<template>
  <StandardModal
    :id="id"
    v-model="shown"
    :modal-title="title"
    :on-cancel="onCancel"
    :should-show-overlay="isSubmitting"
    size="xl"
    centered
    scrollable
    @hide="onCancel"
    @shown="resetModal"
  >
    <div
      class="d-grid my-2"
      style="grid-template-columns: repeat(2, 1fr); column-gap: 1rem"
    >
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.AS_OF_DATE' }) }} <span class="text-danger">*</span>
        </template>
        <DateInput
          :date="form.inceptionDate"
          :max-width="'100%'"
          :input-class="'text-left'"
          style="width: 100%"
        />
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.CREATION_TYPE' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown menu-class="scroll-md w-100 shadow">
          <template #button-content>{{ form.originalMeasurementType }}</template>
          <b-dropdown-item
            v-for="option in originalMeasurementTypes"
            :key="option"
            :active="form.originalMeasurementType === option"
            @click.stop="form.originalMeasurementType = option"
          >
            {{ option }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.RETURN_TYPE' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown menu-class="scroll-md w-100 shadow">
          <template #button-content>{{
            translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: form.returnType })
          }}</template>
          <b-dropdown-item
            v-for="returnTypeOption in returnTypes"
            :key="returnTypeOption"
            :active="form.returnType === returnTypeOption"
            @click.stop="form.returnType = returnTypeOption"
          >
            {{ translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: returnTypeOption }) }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.BASKET_CURRENCY' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown
          menu-class="scroll-md w-100 shadow"
          :disabled="true"
        >
          <template #button-content>
            {{ form.toCurrency }}
          </template>
          <b-dropdown-item
            v-for="option in currencies"
            :key="option"
            :active="form.toCurrency === option"
            @click.stop="form.toCurrency = option"
          >
            {{ option }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.BASKET_NOTIONAL' }) }}<span class="text-danger">*</span>
        </template>
        <!-- Temporarily disable portfolioValue mutation on the submission flow as it's not fully defined yet. -->
        <b-form-input
          v-model="form.portfolioValue"
          disabled
        />
      </b-form-group>
      <b-form-group
        v-if="isEquityBasketPortfolio"
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <template #label>
          {{ translate({ path: 'ANALYSIS_STEPS.BASKET.DIVIDEND_TREATMENT' }) }}<span class="text-danger">*</span>
        </template>
        <StandardFormDropdown menu-class="scroll-md w-100 shadow">
          <template #button-content>{{
            translate({
              path: 'ANALYSIS_STEPS.BASKET.CASH_REINVESTMENT_TYPE',
              item: form.basketDividendTreatment,
            })
          }}</template>
          <b-dropdown-item
            v-for="basketDividendTreatmentOption in basketDividendTreatments"
            :key="basketDividendTreatmentOption"
            :active="form.basketDividendTreatment === basketDividendTreatmentOption"
            @click.stop="form.basketDividendTreatment = basketDividendTreatmentOption"
          >
            {{
              translate({
                path: 'ANALYSIS_STEPS.BASKET.CASH_REINVESTMENT_TYPE',
                item: basketDividendTreatmentOption,
              })
            }}
          </b-dropdown-item>
        </StandardFormDropdown>
      </b-form-group>
      <b-form-group
        :label="translate({ path: 'ANALYSIS_STEPS.BASKET.BASKET_TICKER' })"
        label-cols="5"
        label-class="font-weight-bold pr-2"
        label-align="left"
      >
        <b-form-input
          v-model="form.reference"
          type="text"
          required
        />
      </b-form-group>
    </div>
    <div>
      <!-- TODO: WAA-7712 - Check why sticky header doesn't work  -->
      <b-table
        id="basket-detail-table"
        :fields="fields"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-icon-left="false"
        sort-direction="desc"
        hover
        striped
        sticky-header
        class="mt-2"
      >
        <!-- I Added these template here on purposed coz I will style the color of these 3 columns  -->
        <template #cell(oldWeight)="{ item }">
          <span>{{ item['oldWeight'] }} %</span>
        </template>
        <template #cell(targetWeight)="{ item }">
          <span>{{ item['targetWeight'] }} %</span>
        </template>
        <template #cell(weightChange)="{ item }">
          <span :class="getWeightChangeColorClass(item['weightChange'])">
            {{ getWeightChangeText(item['weightChange']) }}
          </span>
        </template>
        <template #cell(compliant)="{ item }">
          <div class="text-center">
            <Icon
              :icon="getIconToShow(item['compliant'])"
              :color="getIconColorToShow(item['compliant'])"
              class="pr-4"
            />
          </div>
        </template>
      </b-table>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between">
        <StandardButton
          variant="secondary"
          size="sm"
          @click="onCancel"
        >
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </StandardButton>
        <!-- Download is temporarily hidden for MVP -->
        <!-- <StandardButton
          variant="info"
          size="sm"
          disabled
        >
          {{ translate({ path: 'GLOBAL.DOWNLOAD' }) }}
        </StandardButton> -->
        <StandardButton
          id="submit-basket-button"
          variant="primary"
          size="sm"
          :disabled="!isBasketValid"
          @click="shouldShowConfirmationModal = true"
        >
          {{ translate({ path: 'GLOBAL.SUBMIT' }) }}
        </StandardButton>
      </div>
    </template>
    <ConfirmationModal
      :prompt-title="translate({ path: 'CONFIRMATION_DIALOGS.COMPLETE_SUBMITTING_THE_BASKET' })"
      :prompt-subtitle="translate({ path: 'CONFIRMATION_DIALOGS.PLEASE_MAKE_SURE_ALL_CHANGES_ARE_FINALIZED' })"
      :value="shouldShowConfirmationModal"
      @confirm="submitBasket"
      @cancel="shouldShowConfirmationModal = false"
    />
  </StandardModal>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { set } from 'vue-demi';
import { useVModel } from '@vueuse/core';
import useTranslation from '@/composables/useTranslation';
import { ChainedFilter } from '@/composables/useFilter';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { IPortfolioTreeStrategy, isPortfolioTreeStrategy } from '@/types/IPortfolioTree';
import DateInput from '@/layout/components/toolbars/DatePickerToolbar/DatePickerDateInput.vue';
import { Currency } from '@/constants/Currency';
import usePortfolioTree, { getEquityBasketStatus } from '@/composables/usePortfolioTree';
import {
  MeasurementTypeConstants,
  CashReinvestmentTypeConstants,
  BasketCommentTypeConstants,
} from '@/constants/EquityBasket';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';
import { CompliantStatusStyleConfigMap } from '@/types/analytics/EquityBasket';
import {
  useCalculatePortfolioTree,
  useSavePortfolioTree,
  useSubmitBasket,
} from '@/composables/queries/usePortfolioTreeData';
import { SaveChangesModalResponse, SaveChangesStore } from '@/store/modules/SaveChangesStore';
import { useToasts } from '@/composables/useToasts';
import AnalyticsStore from '@/store/modules/AnalyticsStore';
import ConfirmationModal from '@/views/modals/ConfirmationModal.vue';
import { useTriangulation } from '@/composables/useTriangulation';
import { useFormDraft } from '@/composables/useFormDraft';
import { flatten, isEquityBasketPortfolioFn } from '@/utils/portfolioTree';
import Icon from '@/components/standard-components/Icon.vue';
import { usePortfolioTreeDraftIdentifiers } from '@/composables/useCorrectIdentifier';

interface BasketDetailsData {
  code: string;
  name: string;
  currency: string;
  compliant: BasketCommentTypeConstants | null;
  oldWeight: string;
  targetWeight: string;
  weightChange: string;
}

const DEFAULT_BASKET_REFERENCE = '';
const DEFAULT_BASKET_CURRENCY = Currency.USD;
const DEFAULT_BASKET_NAME = '';
const DEFAULT_INCEPTION_DATE = DateTime.now();
const DEFAULT_ORIGIN_MEASUREMENT_TYPE = MeasurementTypeConstants.WEIGHTS;
const DEFAULT_RETURN_TYPE = ReturnTypeConstants.PRICE_RETURN;
const DEFAULT_BASKET_DIVIDEND_TREATMENT = CashReinvestmentTypeConstants.DISTRIBUTE;

export default defineComponent({
  name: 'EquityBasketDetailsModal',
  components: { DateInput, Icon, ConfirmationModal },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    input: (val: boolean) => typeof val === 'boolean',
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();

    const { masterPortfolioTree } = usePortfolioTree();

    const { shouldShowBasketRebalancing } = getEquityBasketStatus(masterPortfolioTree);

    const isEquityBasketPortfolio = isEquityBasketPortfolioFn(masterPortfolioTree);

    const title = computed(() => {
      return `${
        shouldShowBasketRebalancing.value
          ? translate({ path: 'MODALS.REBALANCE_BASKET.TITLE' })
          : translate({ path: 'MODALS.CREATE_BASKET.TITLE' })
      } - ${form.value.name}`;
    });

    const id = computed(() => {
      return shouldShowBasketRebalancing.value ? 'rebalancing-basket-modal' : 'basket-creation-modal';
    });

    const shown = useVModel(props, 'value', emit, { passive: true, eventName: 'input' });

    const sortBy = ref('');
    const sortDesc = ref(true);

    const { DEFAULT_BASKET_NOTIONAL } = useTriangulation();

    const basketDetailsData = computed((): BasketDetailsData[] | [] => {
      if (!masterPortfolioTree.value) {
        return [];
      }

      const flattenedTree = flatten(masterPortfolioTree.value.portfolioTree);
      const flattenedStrategies = flattenedTree.filter((component) => isPortfolioTreeStrategy(component));
      return flattenedStrategies.map((strategy) => getStrategyDetail(strategy));
    });

    const getStrategyDetail = (strategy: IPortfolioTreeStrategy) => {
      const oldWeightForNewBasket = strategy.initialWeighting ?? 0;
      return {
        code: strategy.strategy?.code ?? '-',
        name: strategy.strategy?.longName ?? '-',
        currency: strategy.toCurrency ?? '-',
        compliant: strategy.basketComplianceStatus ?? null,
        oldWeight: oldWeightForNewBasket.toFixed(AnalyticsStore.numDecimalPoints),
        targetWeight: (strategy.weighting ?? 0).toFixed(AnalyticsStore.numDecimalPoints),
        weightChange: (strategy.weighting ?? 0 - oldWeightForNewBasket).toFixed(AnalyticsStore.numDecimalPoints),
      };
    };

    const getWeightChangeColorClass = (weightChange: number) => {
      if (weightChange > 0) return 'text-info';
      if (weightChange < 0) return 'text-danger';

      return '';
    };

    const headers = computed(() => [
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.CODE' }),
        key: 'code',
        isText: true,
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.NAME' }),
        key: 'name',
        isText: true,
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.CURRENCY' }),
        key: 'currency',
        isText: true,
      },
      ...(isEquityBasketPortfolio.value
        ? [
            {
              label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.COMPLIANT' }),
              key: 'compliant',
            },
          ]
        : []),
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.OLD_WEIGHT' }),
        key: 'oldWeight',
        isNumber: true,
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.TARGET_WEIGHT' }),
        key: 'targetWeight',
        isNumber: true,
      },
      {
        label: translate({ path: 'MODALS.CREATE_BASKET.COLUMNS.WEIGHT_CHANGE' }),
        key: 'weightChange',
        isNumber: true,
      },
    ]);

    const fields = computed(() => {
      return headers.value.map((o) => ({
        ...o,
        sortable: true,
        thClass: `align-middle py-2`,
        tdClass: `align-middle text-ellipsis ${o.isNumber ? 'text-right pr-2' : ''} ${o.isText ? 'text-left' : ''} `,
      }));
    });

    const items = computed(() => {
      let rows = ChainedFilter.from(basketDetailsData.value).result();
      rows = orderBy(rows, ['code'], ['asc']);
      return rows;
    });

    const onCancel = () => {
      shown.value = false;
    };

    const formDraft = useFormDraft(
      ref({
        reference: masterPortfolioTree.value?.portfolioTree.reference ?? DEFAULT_BASKET_REFERENCE,
        portfolioValue: masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_BASKET_NOTIONAL,
        toCurrency: masterPortfolioTree.value?.portfolioTree.toCurrency ?? DEFAULT_BASKET_CURRENCY,
        name: masterPortfolioTree.value?.portfolioTree.name ?? DEFAULT_BASKET_NAME,
        inceptionDate: DEFAULT_INCEPTION_DATE,
        originalMeasurementType:
          masterPortfolioTree.value?.portfolioTree.originalMeasurementType ?? DEFAULT_ORIGIN_MEASUREMENT_TYPE,
        returnType: masterPortfolioTree.value?.portfolioTree.returnType ?? DEFAULT_RETURN_TYPE,
        basketDividendTreatment:
          masterPortfolioTree.value?.portfolioTree.basketDividendTreatment ?? DEFAULT_BASKET_DIVIDEND_TREATMENT,
      }),
      [],
    );
    const form = formDraft.form();

    const originalMeasurementTypes = Object.values(MeasurementTypeConstants);
    const currencies = Object.values(Currency);
    const basketDividendTreatments = Object.values(CashReinvestmentTypeConstants);
    const returnTypes: ReturnTypeConstants[] = Object.values(ReturnTypeConstants);

    const getIconColorToShow = (compliantStatus: BasketCommentTypeConstants | null) => {
      // If the status is null, it means the basket is compliant. The reason being the api side doesn't have the enum compliant.
      if (!compliantStatus) return CompliantStatusStyleConfigMap[BasketCommentTypeConstants.COMPLIANT].iconColor;
      return CompliantStatusStyleConfigMap[compliantStatus].iconColor ?? '';
    };

    const getIconToShow = (compliantStatus: BasketCommentTypeConstants | null) => {
      // If the status is null, it means the basket is compliant. The reason being the api side doesn't have the enum compliant.
      if (!compliantStatus) return CompliantStatusStyleConfigMap[BasketCommentTypeConstants.COMPLIANT].icon;
      return CompliantStatusStyleConfigMap[compliantStatus].icon ?? '';
    };

    const { originalSlug } = usePortfolioTreeDraftIdentifiers();

    const { successToast } = useToasts();

    const savePortfolioTreeMutation = useSavePortfolioTree({});
    const calculateMutation = useCalculatePortfolioTree();

    const shouldShowConfirmationModal = ref(false);

    const isSubmitting = computed(
      () =>
        submitBasketMutation.isLoading.value ||
        calculateMutation.isLoading.value ||
        savePortfolioTreeMutation.isLoading.value,
    );

    const submitBasket = async () => {
      shouldShowConfirmationModal.value = false;
      //  Update master portfolio with the selected value
      if (masterPortfolioTree.value) {
        set(masterPortfolioTree.value, 'portfolioTree', {
          ...masterPortfolioTree.value.portfolioTree,
          ...form.value,
        });

        if (SaveChangesStore.customSavingPrompt) {
          const result = await SaveChangesStore.WaitForSaveChangesModalResponse();
          if (result === SaveChangesModalResponse.CANCEL) {
            return;
          }
        }

        // The draft gets saved inside the calculation mutation
        await calculateMutation.mutateAsync({ tree: masterPortfolioTree.value });

        // Ensure the draft is saved to the master portfolio before submitting the basket
        await savePortfolioTreeMutation.mutateAsync(masterPortfolioTree.value.slug);

        // As the submit basket endpoint uses the master portfolio, we need to pass the original slug instead of draft slug here.
        if (!originalSlug.value) return;
        await submitBasketMutation.mutateAsync({ originalSlug: originalSlug.value });
      }

      // close modals
      shown.value = false;
    };

    const submitBasketMutation = useSubmitBasket({
      onSuccess() {
        successToast(
          translate({
            path: 'MODALS.STATIC_DATA_MODAL.BASKET_SUCCESS_SUBMIT_TOAST',
          }),
        );
      },
    });

    // TODO: investigate why formDraft.reset is not working. For the Nov release, I will just reset the form manually.
    const resetModal = () => {
      form.value = {
        reference: masterPortfolioTree.value?.portfolioTree.reference ?? DEFAULT_BASKET_REFERENCE,
        portfolioValue: masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_BASKET_NOTIONAL,
        toCurrency: masterPortfolioTree.value?.portfolioTree.toCurrency ?? DEFAULT_BASKET_CURRENCY,
        name: masterPortfolioTree.value?.portfolioTree.name ?? DEFAULT_BASKET_NAME,
        inceptionDate: DEFAULT_INCEPTION_DATE,
        originalMeasurementType:
          masterPortfolioTree.value?.portfolioTree.originalMeasurementType ?? DEFAULT_ORIGIN_MEASUREMENT_TYPE,
        returnType: masterPortfolioTree.value?.portfolioTree.returnType ?? DEFAULT_RETURN_TYPE,
        basketDividendTreatment:
          masterPortfolioTree.value?.portfolioTree.basketDividendTreatment ?? DEFAULT_BASKET_DIVIDEND_TREATMENT,
      };
    };

    /**
     *  To avoid the timezone issue, we only allow any date that is greater than the current date (on hold)
     */
    const isBasketValid = computed(() => {
      return (
        form.value.basketDividendTreatment &&
        // Temporarily commented out the validation as the logic for inception date is not fully defined.
        // form.value.inceptionDate > DateTime.now() &&
        form.value.name &&
        form.value.originalMeasurementType &&
        form.value.portfolioValue &&
        form.value.returnType &&
        form.value.toCurrency
      );
    });

    const getWeightChangeText = (weightChange: number) => {
      if (weightChange > 0) return `+ ${weightChange} %`;
      if (weightChange < 0) return `- ${weightChange} %`;

      return `${weightChange} %`;
    };

    return {
      translate,
      shown,
      onCancel,
      fields,
      items,
      sortBy,
      sortDesc,
      form,
      currencies,
      basketDetailsData,
      masterPortfolioTree,
      originalMeasurementTypes,
      returnTypes,
      basketDividendTreatments,
      getIconToShow,
      getIconColorToShow,
      submitBasket,
      isBasketValid,
      shouldShowConfirmationModal,
      getWeightChangeColorClass,
      resetModal,
      getWeightChangeText,
      isSubmitting,
      title,
      id,
      isEquityBasketPortfolio,
    };
  },
});
</script>
