/**
 * Note that all the keys in this file should follow the naming convention of `premialab:{ENTITY_NAME}`.
 * There are some legacy entities that do not have this prefix, but all new entities should have it.
 */
export enum LocalStorageEntities {
  // Allen 12/8/2024 -> Legacy entities: Schedule to replace after 1 month (TBC)
  REGRESSION_METHODOLOGY = 'regression-methodology',
  // REGRESSION_RETURN_INTERVAL = 'regression-return-interval',
  SHOULD_USE_ADMIN_BACK_DOOR = 'should-use-admin-back-door',
  USE_STRATEGY_MASK_UPDATED = 'use-strategy-mask-updated',
  RISK_PREFERENCES = 'constituent-risk-preferences',
  SHOULD_USE_USER_DEFAULT_PERIOD_RETURN_INTERVAL = 'should-use-user-default-period-return-interval',
  COMPLIANCE_RULE = 'compliance-rule',
  INCLUDE_UNEXPLAINED_CASH_AND_FEES = 'include-unexplained-in-cash-and-fees',
  INCLUDE_INTRADAY_PERFORMANCE = 'include-intraday-performance',
  SHOW_DISTRIBUTION_GRAPHS = 'show-distribution-graphs',
  HIDE_BETA_FEATURES = 'hide-beta-features',
  ACTIVE_RETURN_MODE = 'active-return-mode',
  ACTIVE_RETURN_REBALANCING_TYPE = 'active-return-rebalancing-type',
  UNIVERSE_OPTIONS = 'universe-options-new',
  REGRESSION_RETURN_ACTIVE = 'regression-return-active',

  // Replacement of the legacy entities
  REGRESSION_METHODOLOGY_V2 = 'premialab:regression-methodology',
  SHOULD_USE_ADMIN_BACK_DOOR_V2 = 'premialab:should-use-admin-back-door',
  USE_STRATEGY_MASK_UPDATED_V2 = 'premialab:use-strategy-mask-updated',
  RISK_PREFERENCES_V2 = 'premialab:constituent-risk-preferences',
  SHOULD_USE_USER_DEFAULT_PERIOD_RETURN_INTERVAL_V2 = 'premialab:should-use-user-default-period-return-interval',
  COMPLIANCE_RULE_V2 = 'premialab:compliance-rule',
  INCLUDE_UNEXPLAINED_CASH_AND_FEES_V2 = 'premialab:include-unexplained-in-cash-and-fees',
  INCLUDE_INTRADAY_PERFORMANCE_V2 = 'premialab:include-intraday-performance',
  SHOW_DISTRIBUTION_GRAPHS_V2 = 'premialab:show-distribution-graphs',
  HIDE_BETA_FEATURES_V2 = 'premialab:hide-beta-features',
  ACTIVE_RETURN_MODE_V2 = 'premialab:active-return-mode',
  ACTIVE_RETURN_REBALANCING_TYPE_V2 = 'premialab:active-return-rebalancing-type',
  UNIVERSE_OPTIONS_V2 = 'premialab:universe-options-new',
  REGRESSION_RETURN_ACTIVE_V2 = 'premialab:regression-return-active',
  UNIVERSE_TABLE_COLUMN_REFERENCES = 'premialab:universe-table-column-preferences',

  // Existing entities that already align with the naming convention
  DSBColumns = `premialab:DSBColumns`,
  // LastUsedRegression = `premialab:LastUsedRegression`,
  LastUsedPortfolioSlug = `premialab:LastUsedPortfolioSlug`,
  LastUsedBasketSlug = `premialab:LastUsedBasketSlug`,
  LastUsedAnalysisStep = `premialab:LastUsedAnalysisStep`,
  // ConstituentRiskPreferences = `premialab:ConstituentRiskPreferences`,
  AnalysisPeriod = `premialab:AnalysisPeriod`,
  TRIANGULATION_AS_OF_DATE = 'premialab:triangulation-as-of-date',

  // Constituent Position
  POSITION_PREFERENCES = 'premialab:constituent-position-preferences',
}
