import { Currency } from '@/constants/Currency';
import { MaybeRef, useLocalStorage } from '@vueuse/core';
import { Ref, computed, nextTick, onBeforeUnmount, unref, watch } from 'vue';
import { set } from 'vue-demi';
import { MetricConstants } from '@/constants/MetricConstants';
import { Dictionary, Route, VueRouter } from 'vue-router/types/router';
import { useQueryParams } from './useAnalysisQueryParameters';
import {
  CommodityFilterConstants,
  ExposureConstants,
  FilterConstants,
  EquityGroupByConstants,
  LadderTitles,
  RiskTableTabs,
  RiskTabs,
  StressTestDropdownType,
  TableLayerConstants,
  UnitConstants,
  VarGrouping,
  VarMetrics,
  VarNotional,
  customTests,
  historyTestOption,
  equityGroupByConstants,
  HistoryGroupBy,
} from '@/types/analytics/ConstituentRisk';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { useIsPdf } from './usePdf';
import { useFeatureFlag } from './useFeatureFlag';
import { useConstituentRiskTableUtilities } from './useConstituentRiskTableUtilities';
import { useConstituentRiskAnalysisSteps } from './useAnalysisSteps';
import { getFieldsToCall, getTableComponent } from './useConstituentRiskParams';

export type PreferenceOptions = {
  unit: UnitConstants;
  display: ExposureConstants;
  basisPoints: number;
  var: VarMetrics;
  'stress-test': string;
  equity: MetricConstants;
  'interest-rate': string;
  credit: string;
  fx: MetricConstants;
  commodity: MetricConstants;
  trackSegment: FilterConstants.PORTFOLIO;
  tableLayers: TableLayerConstants[];
  assetRiskCols: string[];
  customRiskCols: string[];
  filterNullQuantityPosition: boolean;
};

export type VarPreference = {
  metric: VarMetrics;
  category: FilterConstants;
  grouping: VarGrouping;
  metric2: VarNotional;
  category2: FilterConstants;
  display: ExposureConstants;
  historyCategory: FilterConstants | HistoryGroupBy;
  tableMetricName: RiskTableTabs;
  exposureRiskCols: string[];
  factorRiskCols: string[];
  varRiskCols: string[];
};

export type StressTestPreference = {
  category: FilterConstants;
  filter: FilterConstants;
  filterApplied: string;
  testOptions: StressTestDropdownType[];
  historyTestOption: StressTestDropdownType;
  historyCategory: FilterConstants | HistoryGroupBy;
  tableMetricName: RiskTableTabs;
  transitiveStressRiskCols: string[];
  historicalStressRiskCols: string[];
};

export type LadderPreference = {
  ladder1: LadderTitles;
  ladder2: LadderTitles;
};
export type EquityPreference = {
  metric: MetricConstants;
  display: ExposureConstants;
  filterLevel1: EquityGroupByConstants | FilterConstants;
  filterLevel2: EquityGroupByConstants | FilterConstants;
  /**
   * Instrument is a bit special. This is one preference that we will clear regularly.
   * If instrument is populated, we will show the drilled down graph for the given sensitivity.
   * If it's not populated, then we show the 'overview' graph.
   */
  instrument: string;
  historyCategory: FilterConstants;
  tableMetricName: RiskTableTabs;
  ladderTableLayers: TableLayerConstants[];
  sensitivityRiskCols: string[];
  ladderRiskCols: string[];
} & LadderPreference;

export type InterestRatePreference = {
  metric: MetricConstants;
  display: ExposureConstants;
  instrument: Currency | 'All';
  category: FilterConstants;
  historyCategory: FilterConstants;
  tableMetricName: RiskTableTabs;
  tableLayers: TableLayerConstants[];
  ladderTableLayers: TableLayerConstants[];
  sensitivityRiskCols: string[];
  ladderRiskCols: string[];
} & LadderPreference;

export type CreditPreference = {
  display: ExposureConstants;
  instrument: string;
  category: FilterConstants;
  historyCategory: FilterConstants;
  tableMetricName: RiskTableTabs;
  tableLayers: TableLayerConstants[];
  sensitivityRiskCols: string[];
};

export type FxPreference = {
  metric: MetricConstants;
  instrument: string;
  display: ExposureConstants;
  category: FilterConstants;
  filter: FilterConstants;
  historyCategory: FilterConstants;
  tableMetricName: RiskTableTabs;
  tableLayers: TableLayerConstants[];
  ladderTableLayers: TableLayerConstants[];
  sensitivityRiskCols: string[];
  ladderRiskCols: string[];
} & LadderPreference;

export type CommodityPreference = {
  metric: MetricConstants;
  filter: CommodityFilterConstants;
  display: ExposureConstants;
  instrument: string;
  tableLayers: TableLayerConstants[];
  category: FilterConstants | CommodityFilterConstants;
  historyCategory: FilterConstants | HistoryGroupBy;
  tableMetricName: RiskTableTabs;
  ladderTableLayers: TableLayerConstants[];
  sensitivityRiskCols: string[];
  ladderRiskCols: string[];
} & LadderPreference;

export type PreferenceStorage = {
  /**
   * Change the hash date if you mess up and want to force everyone's cookies to be cleared :)
   */
  hash: { date: string };
  /**
   * Mostly consists of parameters used by the ribbon, parameters modal, and other global settings
   */
  options: PreferenceOptions;
  [RiskTabs.VAR]: VarPreference;
  [RiskTabs.STRESS_TEST]: StressTestPreference;
  [RiskTabs.EQUITY]: EquityPreference;
  [RiskTabs.INTEREST_RATE]: InterestRatePreference;
  [RiskTabs.CREDIT]: CreditPreference;
  [RiskTabs.FX]: FxPreference;
  [RiskTabs.COMMODITY]: CommodityPreference;
};

/**
 * Checks that the default preferences object keys match with that of the saved preferences
 */
const compareKeys = (
  defaultPreferences: Record<string, unknown>,
  savedPreferences: Record<string, unknown>,
): boolean => {
  const defaultKeys = Object.keys(defaultPreferences).sort();
  const savedKeys = Object.keys(savedPreferences).sort();
  return JSON.stringify(defaultKeys) === JSON.stringify(savedKeys);
};

export const defaultAssetCols = [
  'Strategy',
  'Asset Class',
  'Product Type',
  'Underlying',
  'Position Date',
  'Currency',
  'Foreign Currency',
  'Start Date',
  'Maturity',
  'Strike',
  'Instrument ID',
];

export const defaultExposureCols = [
  'Value',
  'Exposure Notional Net',
  'Exposure Notional Gross',
  'Equivalent Delta Notional',
];

export const defaultFactorColumnCols = [
  'EFM Beta',
  'EFM Carbon Efficiency',
  'EFM Growth',
  'EFM Investment Quality',
  'EFM Momentum',
  'EFM Profitability',
  'EFM Size',
  'EFM Value',
  'EFM Dividend Yield',
];

export const defaultTransitiveStressCols = [
  'MSCI World -10%',
  'S&P 500 -10%',
  'VIX +20 Points',
  'IR G3 +50 bps',
  'IR G3 Steepening 2/10 (-20/20 bps)',
  'CCY G10 vs USD -5%',
  'MXWO -10%, IR USD, EUR +75 bps',
  'BCOMTR +10%',
];

export const defaultEquitySensitivityCols = [
  'EQ.Delta',
  'EQ.Gamma',
  'EQ.Theta',
  'EQ.Vega',
  'VOL.Delta',
  'VOL.Gamma',
  'VOL.Vega',
];

export const defaultInterestRateSensitivityCols = [
  'IR.DV01(1bp)',
  'IR.Theta',
  'IR.Vega(1%) BS',
  'IR.Vega(1bp) normal',
  'IR Steepening 2/10 (-20/20 bps)',
];

export const defaultCreditSensitivityCols = [
  'CR.CS01(1bp)',
  'CR.Theta',
  'CR Steepening 2/10 (-20/20 bps)',
  'Duration Times Spread (DTS)',
];

export const defaultFXSensitivityCols = ['FX.Delta[USD]', 'FX.Gamma', 'FX.Theta', 'FX.Vega'];

export const defaultCommoditySensitivityCols = ['CM.Delta', 'CM.Gamma', 'CM.Theta', 'CM.Vega'];

export type InstrumentTabs =
  | RiskTabs.EQUITY
  | RiskTabs.INTEREST_RATE
  | RiskTabs.CREDIT
  | RiskTabs.FX
  | RiskTabs.COMMODITY;

export const defaultInstruments = {
  [RiskTabs.EQUITY]: 'All',
  [RiskTabs.INTEREST_RATE]: Currency.USD,
  [RiskTabs.CREDIT]: 'US',
  [RiskTabs.FX]: 'All',
  [RiskTabs.COMMODITY]: 'Brent Crude Oil',
};

const defaultPreferences: PreferenceStorage = {
  hash: { date: '2023-04-26' },
  options: {
    unit: UnitConstants.CURRENCY,
    display: ExposureConstants.NET,
    basisPoints: 1,
    var: VarMetrics.CVAR_95,
    'stress-test': 'S&P 500 -10%',
    equity: MetricConstants.DELTA,
    'interest-rate': `DV01 (+1 bp)`,
    credit: `CS01 (+1 bp)`,
    fx: MetricConstants.DELTA,
    commodity: MetricConstants.DELTA,
    trackSegment: FilterConstants.PORTFOLIO,
    tableLayers: [TableLayerConstants.STRATEGY, TableLayerConstants.PRODUCT_TYPE, TableLayerConstants.INSTRUMENT],
    assetRiskCols: defaultAssetCols,
    customRiskCols: [],
    filterNullQuantityPosition: true,
  },
  [RiskTabs.VAR]: {
    metric: VarMetrics.CVAR_95,
    metric2: VarNotional.EXPOSURE_NOTIONAL,
    category: FilterConstants.RISK_CURRENCY,
    grouping: VarGrouping.ALLOCATED,
    category2: FilterConstants.FACTOR,
    display: ExposureConstants.LONG,
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.VAR,
    exposureRiskCols: defaultExposureCols,
    factorRiskCols: defaultFactorColumnCols,
    varRiskCols: [],
  },
  [RiskTabs.STRESS_TEST]: {
    category: FilterConstants.FACTOR,
    filter: FilterConstants.FACTOR,
    filterApplied: 'All',
    testOptions: customTests,
    historyTestOption: historyTestOption,
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.TRANSITIVE_STRESS,
    transitiveStressRiskCols: defaultTransitiveStressCols,
    historicalStressRiskCols: [],
  },
  [RiskTabs.EQUITY]: {
    metric: MetricConstants.DELTA,
    ladder1: LadderTitles.SPOT,
    ladder2: LadderTitles.VOL,
    display: ExposureConstants.LONG,
    filterLevel1: FilterConstants.COUNTRY,
    filterLevel2: EquityGroupByConstants.SECTOR_LEVEL_1,
    instrument: 'All',
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.SENSITIVITY,
    ladderTableLayers: [TableLayerConstants.VOLATILITY_SHOCK, TableLayerConstants.INSTRUMENT],
    sensitivityRiskCols: defaultEquitySensitivityCols,
    ladderRiskCols: [],
  },
  [RiskTabs.INTEREST_RATE]: {
    metric: MetricConstants.DELTA,
    display: ExposureConstants.LONG,
    instrument: 'All',
    ladder1: LadderTitles.SPOT,
    ladder2: LadderTitles.VOL,
    category: FilterConstants.RISK_CURRENCY,
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.SENSITIVITY,
    tableLayers: [TableLayerConstants.RISK_CURRENCY],
    ladderTableLayers: [
      TableLayerConstants.VOLATILITY_SHOCK,
      TableLayerConstants.RISK_CURRENCY,
      TableLayerConstants.INSTRUMENT,
    ],
    sensitivityRiskCols: defaultInterestRateSensitivityCols,
    ladderRiskCols: [],
  },
  [RiskTabs.CREDIT]: {
    display: ExposureConstants.LONG,
    instrument: 'US',
    category: FilterConstants.COUNTRY,
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.SENSITIVITY,
    tableLayers: [TableLayerConstants.COUNTRY],
    sensitivityRiskCols: defaultCreditSensitivityCols,
  },
  [RiskTabs.FX]: {
    metric: MetricConstants.DELTA,
    display: ExposureConstants.LONG,
    category: FilterConstants.FACTOR,
    filter: FilterConstants.FACTOR,
    ladder1: LadderTitles.SPOT,
    ladder2: LadderTitles.VOL,
    instrument: 'All',
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.SENSITIVITY,
    tableLayers: [TableLayerConstants.RISK_CURRENCY],
    ladderTableLayers: [TableLayerConstants.STRATEGY, TableLayerConstants.PRODUCT_TYPE, TableLayerConstants.INSTRUMENT],
    sensitivityRiskCols: defaultFXSensitivityCols,
    ladderRiskCols: [],
  },
  [RiskTabs.COMMODITY]: {
    metric: MetricConstants.DELTA,
    ladder1: LadderTitles.SPOT,
    ladder2: LadderTitles.VOL,
    filter: CommodityFilterConstants.COMMODITY,
    display: ExposureConstants.LONG,
    instrument: 'Brent Crude Oil',
    tableLayers: [TableLayerConstants.SECTOR, TableLayerConstants.COMMODITY],
    category: CommodityFilterConstants.COMMODITY,
    historyCategory: FilterConstants.STRATEGY,
    tableMetricName: RiskTableTabs.COMMODITY_DELTA,
    ladderTableLayers: [TableLayerConstants.SECTOR, TableLayerConstants.COMMODITY],
    sensitivityRiskCols: defaultCommoditySensitivityCols,
    ladderRiskCols: [],
  },
};

const isPdf = useIsPdf();
export function useRiskPreferences(initialParams?: PreferenceStorage) {
  const initialState = initialParams ?? defaultPreferences;

  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyPreferences = useLocalStorage<PreferenceStorage>(LocalStorageEntities.RISK_PREFERENCES, {
    ...initialState,
  });
  /**
   * If constituent risk preferences exist, replace the default preferences
   */
  const preferences = useLocalStorage<PreferenceStorage>(
    LocalStorageEntities.RISK_PREFERENCES_V2,
    legacyPreferences.value,
  );

  /**
   * Checks that the saved preferences have the proper structure.
   * If the structure has changed during development and the saved preferences have a different structure,
   * then replace with the default.
   *
   * on PDF we ignore this so that we may pass specific values to the PDF generator
   */
  const validateState = (isOnPdfPage: boolean) => {
    let sameKeys = true;
    if (!preferences.value || isOnPdfPage) return;

    if (
      !compareKeys(defaultPreferences, preferences.value) ||
      defaultPreferences.hash.date !== preferences.value.hash.date
    ) {
      sameKeys = false;
    }

    if (sameKeys) {
      for (const key of Object.keys(defaultPreferences)) {
        if (!preferences.value) break;
        if (
          !compareKeys(defaultPreferences[key as RiskTabs | 'options'], preferences.value[key as RiskTabs | 'options'])
        ) {
          sameKeys = false;
        }
      }
    }

    if (!sameKeys) {
      preferences.value = {
        ...defaultPreferences,
      };
    }
  };
  validateState(isPdf.value);

  const varMetric1 = computed(() => {
    if (preferences.value.var.metric === VarMetrics.ESG_DISTRIBUTION) return preferences.value.var.metric;
    return `Historical ${preferences.value.var.metric} ${preferences.value.var.grouping}`;
  });

  /**
   * Must supply router and route yourself, otherwise it craps out when
   * amcharts tries to call useRouter() and useRoute() on the onclick event
   */
  const drillDown = (router: VueRouter, route: MaybeRef<Route>, sensitivity: RiskTabs, instrument = 'All') => {
    if (sensitivity === RiskTabs.EQUITY) {
      preferences.value[RiskTabs.EQUITY].instrument = instrument;
    }
    const query = {
      ...unref(route).query,
      drillDown: 'true',
    };

    router.push({
      query,
    });
  };

  const goToOverview = (router: VueRouter, route: MaybeRef<Route>) => {
    const query = { ...unref(route).query };
    delete query.drillDown;

    router.push({
      query,
    });
  };

  /**
   * Sets the new preferences
   * @param param paramPreferences object property to change
   * @param option Value to change it to
   */
  const setTablePreference = (param: string, option: unknown, columnNames?: string[]) => {
    const { isCommodityDeltaGamma, isTotalColumnInTable, isLadderTable, getTableComponentAndFields } =
      useConstituentRiskTableUtilities();
    const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();
    let path = activeAnalysisSubstep.value?.path as keyof PreferenceStorage;
    if (!path) return;
    if (
      param !== 'tableMetricName' &&
      !isCommodityDeltaGamma.value &&
      !isTotalColumnInTable.value &&
      !isLadderTable.value
    ) {
      path = 'options';
    }

    set(preferences.value[path], param, option);

    // Only get the the list of fields which have not already been asked
    nextTick(() => {
      if ((param === 'tableLayers' || param === 'ladderTableLayers') && columnNames) {
        const riskFieldsToCall = getFieldsToCall();
        const riskTableComponent = getTableComponent();
        const { tableComponent, fields } = getTableComponentAndFields(columnNames);

        riskFieldsToCall.value = fields;
        riskTableComponent.value = tableComponent;
      }
    });
  };

  return {
    preferences,
    varMetric1,
    goToOverview,
    drillDown,
    setTablePreference,
  };
}

const varMetrics = Object.values(VarMetrics);
const varNotionals = Object.values(VarNotional);
const filterConstants = Object.values(FilterConstants);
const varGroupings = Object.values(VarGrouping);
const exposureConstants = Object.values(ExposureConstants);
const tableMetricGroupings = Object.values(RiskTableTabs);
const metricConstants = Object.values(MetricConstants);
const groupByConstants = equityGroupByConstants;
const ladderTitles = Object.values(LadderTitles);

const DEBUG = false;

/**
 * Only allows the setting of a VALID value to a preference. A little annoying, but probably necessary.
 */
export function mapQueryToPreferences(query: Dictionary<string | (string | null)[]>, sensitivity: RiskTabs) {
  const { preferences } = useRiskPreferences();

  for (const [key, value] of Object.entries(query).filter(([key]) => {
    return key.includes(sensitivity);
  })) {
    const [, sKey] = key.split('.');
    if (DEBUG) console.log({ sKey });
    if (!(sKey in defaultPreferences[sensitivity])) continue;

    if (sensitivity === RiskTabs.VAR) {
      if (sKey === 'metric') {
        if (varMetrics.includes(value as VarMetrics)) {
          preferences.value[sensitivity].metric = value as VarMetrics;
          if (DEBUG) console.log('setting metric to', value);
        }
        continue;
      }
      if (sKey === 'metric2') {
        if (varNotionals.includes(value as VarNotional)) {
          preferences.value[sensitivity].metric2 = value as VarNotional;
          if (DEBUG) console.log('setting metric2 to', value);
        }
        continue;
      }

      if (sKey === 'category' || sKey === 'category2' || sKey === 'historyCategory') {
        if (filterConstants.includes(value as FilterConstants)) {
          preferences.value[sensitivity][sKey] = value as FilterConstants;
          if (DEBUG) console.log(`setting ${sKey} to`, value);
        }
        continue;
      }

      if (sKey === 'grouping') {
        if (varGroupings.includes(value as VarGrouping)) {
          preferences.value[sensitivity].grouping = value as VarGrouping;
          if (DEBUG) console.log('setting grouping to', value);
        }
        continue;
      }

      if (sKey === 'display') {
        if (exposureConstants.includes(value as ExposureConstants)) {
          preferences.value[sensitivity].display = value as ExposureConstants;
          if (DEBUG) console.log('setting display to', value);
        }
        continue;
      }

      if (sKey === 'tableMetricName') {
        if (tableMetricGroupings.includes(value as RiskTableTabs)) {
          preferences.value[sensitivity].tableMetricName = value as RiskTableTabs;
          if (DEBUG) console.log('setting tableMetricName to', value);
        }
        continue;
      }

      // TODO: get table cols working
      // if (sKey === 'exposureRiskCols') {
      //   preferences.value[sensitivity].exposureRiskCols = value as string[];

      // }
    }

    if (sensitivity === RiskTabs.STRESS_TEST) {
      if (DEBUG) console.log('STRESS TEST...', value);

      if (sKey === 'category') {
        if (filterConstants.includes(value as FilterConstants)) {
          preferences.value[sensitivity][sKey] = value as FilterConstants;
          if (DEBUG) console.log(`setting ${sKey} to`, value);
        }
        continue;
      }

      if (sKey === 'filter') {
        if (filterConstants.includes(value as FilterConstants)) {
          preferences.value[sensitivity][sKey] = value as FilterConstants;
          if (DEBUG) console.log(`setting ${sKey} to`, value);
        }
      }
    }

    if (sensitivity === RiskTabs.EQUITY) {
      if (DEBUG) console.log('EQUITY...', value);
      if (sKey === 'metric') {
        if (metricConstants.includes(value as MetricConstants)) {
          preferences.value[sensitivity].metric = value as MetricConstants;
          if (DEBUG) console.log('setting metric to', value);
        }
        continue;
      }

      if (sKey === 'display') {
        if (exposureConstants.includes(value as ExposureConstants)) {
          preferences.value[sensitivity].display = value as ExposureConstants;
          if (DEBUG) console.log('setting display to', value);
        }
        continue;
      }

      if (sKey === 'filterLevel1' || sKey === 'filterLevel2') {
        if (groupByConstants.includes(value as EquityGroupByConstants | FilterConstants)) {
          preferences.value[sensitivity][sKey] = value as EquityGroupByConstants | FilterConstants;
          if (DEBUG) console.log(`setting ${sKey} to`, value);
        }
      }

      // TODO: how to handle instrument (just a string)?

      if (sKey === 'ladder1' || sKey === 'ladder2') {
        if (ladderTitles.includes(value as LadderTitles)) {
          preferences.value[sensitivity][sKey] = value as LadderTitles;
          if (DEBUG) console.log(`setting ${sKey} to`, value);
        }
      }

      if (sKey === 'historyCategory') {
        if (filterConstants.includes(value as FilterConstants)) {
          preferences.value[sensitivity][sKey] = value as FilterConstants;
          if (DEBUG) console.log(`setting ${sKey} to`, value);
        }
        continue;
      }

      if (sKey === 'tableMetricName' && tableMetricGroupings.includes(value as RiskTableTabs)) {
        preferences.value[sensitivity].tableMetricName = value as RiskTableTabs;
        if (DEBUG) console.log('setting tableMetricName to', value);
        continue;
      }

      // TODO: get table cols working
      // if (sKey === 'sensitivityRiskCols') {
      //   preferences.value[sensitivity].sensitivityRiskCols = value as string[];
      // }

      // if (sKey === 'ladderRiskCols') {
      //   preferences.value[sensitivity].ladderRiskCols = value as string[];
      // }
    }
  }
}

/**
 * This function is ONLY designed to work with the risk preferences.
 * We use some similar logic for other variables on the risk pages in the query,
 * but we must handle them slightly differently.
 *
 * This function is designed to watch for changes to preferences and update the query
 * in the URL with these values. Then, when the component that calls this function is
 * unmounted, it removes those values from the URL query.
 */
export function useUpdateRiskPrefsQuery(prefsToRemove: string[]) {
  const { preferences } = useRiskPreferences();
  const queryParams = useQueryParams();

  watch(
    prefsToRemove.map((pref) => {
      const vars = pref.split('.');
      const firstLevel = vars[0] as keyof PreferenceStorage;
      const secondLevel = vars[1];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return () => (preferences.value[firstLevel] as any)[secondLevel];
    }),
    (newVals) => {
      const newQuery = { ...queryParams.value };

      for (let i = 0; i < prefsToRemove.length; i++) {
        newQuery[prefsToRemove[i]] = newVals[i];
      }

      queryParams.value = newQuery;
    },
    { immediate: true },
  );

  onBeforeUnmount(() => {
    const prefsRemoved = { ...queryParams.value };

    for (const pref of prefsToRemove) {
      delete prefsRemoved[pref];
    }

    queryParams.value = prefsRemoved;
  });
}

export function useValidateInstrument(tab: InstrumentTabs, valuesToCheck: Ref<string[]>) {
  const { preferences } = useRiskPreferences();

  watch(
    () => preferences.value[tab].instrument,
    (newVal) => {
      if (!valuesToCheck.value.includes(newVal)) {
        preferences.value[tab].instrument = defaultInstruments[tab];
      }
    },
  );
}

export function removeRestrictedPreferences() {
  const { preferences } = useRiskPreferences();
  const { getShouldDisplayDistributionGraphs } = useFeatureFlag();
  const shouldDisplayDistributionGraphs = getShouldDisplayDistributionGraphs();

  watch(
    shouldDisplayDistributionGraphs,
    (newVal) => {
      // WATCH IMMEDIATE RUNS *REALLY FAST*
      // we must wait for one tick so that we have the proper value inside the risk preferences
      nextTick(() => {
        if (!newVal) {
          if (preferences.value.var.metric === VarMetrics.ESG_DISTRIBUTION) {
            set(preferences.value, 'var', { ...preferences.value.var, metric: VarMetrics.VAR_99 });
          }
          if (preferences.value.var.metric2 === VarNotional.FACTOR_DISTRIBUTION) {
            set(preferences.value.var, 'metric2', VarNotional.EXPOSURE_NOTIONAL);
          }
        }
      });
    },
    { immediate: true },
  );
}
