import { ChartTypes } from '@/types/ChartTypes';
import { AnalysisSubstep } from '@/types/analytics/AnalysisStep';
import { ApplicationDefaults } from '@/constants/ApplicationDefaults';
import { IConstituentRisk } from '@/types/IConstituentRisk';
import { MetricConstants } from '@/constants/MetricConstants';

/**
 * Path name for a {@link RiskTabProperName}
 */
export enum RiskTabs {
  VAR = 'var',
  STRESS_TEST = 'stress-test',
  EQUITY = 'equity',
  INTEREST_RATE = 'interest-rate',
  CREDIT = 'credit',
  FX = 'fx',
  COMMODITY = 'commodity',
}

/**
 * Proper name corresponding to a {@link RiskTabs}
 */
export enum RiskTabProperName {
  VAR = 'VaR',
  STRESS_TEST = 'Stress Test',
  EQUITY = 'Equity',
  INTEREST_RATE = 'Interest Rate',
  CREDIT = 'Credit',
  FX = 'FX',
  COMMODITY = 'Commodity',
}

export const CONSTITUENT_RISK_VAR: AnalysisSubstep = {
  title: RiskTabProperName.VAR,
  path: RiskTabs.VAR,
  defaultComponents: {
    name: ChartTypes.VAR_WATERFALL,
    subComponents: [
      { name: ChartTypes.VAR_COLUMNS },
      { name: ChartTypes.VAR_DONUT },
      { name: ChartTypes.VAR_COLUMNS_EXPOSURE },
      { name: ChartTypes.VAR_ESG_DISTRIBUTION },
      { name: ChartTypes.VAR_FACTOR_DISTRIBUTION },
    ],
  },
  historyComponents: {
    name: ChartTypes.VAR_HISTORY,
    subComponents: [{ name: ChartTypes.VAR_EXPOSURE_HISTORY }],
  },
};

export const CONSTITUENT_RISK_STRESS_TEST: AnalysisSubstep = {
  title: RiskTabProperName.STRESS_TEST,
  path: RiskTabs.STRESS_TEST,
  defaultComponents: { name: ChartTypes.STRESS_TEST_MAIN_GRAPH },
  historyComponents: { name: ChartTypes.STRESS_TEST_HISTORY },
};

export const CONSTITUENT_RISK_EQUITY: AnalysisSubstep = {
  title: RiskTabProperName.EQUITY,
  path: RiskTabs.EQUITY,
  defaultComponents: {
    name: ChartTypes.EQUITY_TREE_MAP,
    subComponents: [{ name: ChartTypes.EQUITY_LAYERED_BAR }, { name: ChartTypes.EQUITY_LADDER }],
  },
  metricComponent: {
    [MetricConstants.CONVEXITY]: { name: ChartTypes.EQUITY_LADDER },
  },
  historyComponents: { name: ChartTypes.EQUITY_HISTORY },
};

export const CONSTITUENT_RISK_INTEREST_RATE: AnalysisSubstep = {
  title: RiskTabProperName.INTEREST_RATE,
  path: RiskTabs.INTEREST_RATE,
  defaultComponents: {
    name: ChartTypes.INTEREST_RATE_MAIN_GRAPH,
    subComponents: [{ name: ChartTypes.INTEREST_RATE_CHILD_1 }],
  },
  metricComponent: {
    [MetricConstants.CONVEXITY_NORM]: { name: ChartTypes.INTEREST_RATE_LADDER },
    [MetricConstants.CONVEXITY_BS]: { name: ChartTypes.INTEREST_RATE_LADDER },
  },
  historyComponents: { name: ChartTypes.INTEREST_RATE_HISTORY },
};

export const CONSTITUENT_RISK_CREDIT: AnalysisSubstep = {
  title: RiskTabProperName.CREDIT,
  path: RiskTabs.CREDIT,
  defaultComponents: {
    name: ChartTypes.CREDIT_MAIN_GRAPH,
    subComponents: [{ name: ChartTypes.CREDIT_CHILD_1 }],
  },
  historyComponents: { name: ChartTypes.CREDIT_HISTORY },
};

export const CONSTITUENT_RISK_FX: AnalysisSubstep = {
  title: RiskTabProperName.FX,
  path: RiskTabs.FX,
  defaultComponents: { name: ChartTypes.FX_MAIN_GRAPH },
  metricComponent: {
    [MetricConstants.CONVEXITY]: { name: ChartTypes.FX_LADDER },
  },
  historyComponents: { name: ChartTypes.FX_HISTORY },
};

export const CONSTITUENT_RISK_COMMODITY: AnalysisSubstep = {
  title: RiskTabProperName.COMMODITY,
  path: RiskTabs.COMMODITY,
  defaultComponents: {
    name: ChartTypes.COMMODITY_TREE_MAP,
    subComponents: [{ name: ChartTypes.COMMODITY_BAR_LINE }],
  },
  metricComponent: {
    [MetricConstants.CONVEXITY]: { name: ChartTypes.COMMODITY_LADDER },
  },
  historyComponents: { name: ChartTypes.COMMODITY_HISTORY },
};

export const LEGEND_DATA_MAX = 20;

export enum ExposureConstants {
  LONG = 'Long',
  SHORT = 'Short',
  NET = 'Net',
  GROSS = 'Gross',
}

export enum UnitConstants {
  CURRENCY = '$',
  PERCENT_VALUE = '%value',
  PERCENT_SELECTION = '%selection',
}

export enum EquityGroupByConstants {
  SECTOR_LEVEL_1 = 'Sector Level 1',
  SECTOR_LEVEL_2 = 'Sector Level 2',
}

export enum FilterConstants {
  ASSET_CLASS = 'Asset Class',
  COUNTRY = 'Country',
  CURRENCY = 'Currency',
  RISK_CURRENCY = 'Currency of Risk',
  FACTOR = 'Factor',
  LS = 'Long/Short',
  PORTFOLIO = 'Portfolio',
  PRODUCT_TYPE = 'Product Type',
  PROVIDER = 'Provider',
  SECTOR = 'Sector',
  STRATEGY = 'Strategy',
}

export enum HistoryGroupBy {
  UNDERLYING = 'Underlying',
  REGION = 'Region',
  SECTOR = 'Sector',
  COMMODITY = 'Commodity',
}

export enum CommodityFilterConstants {
  COMMODITY = 'Commodity',
  SECTOR = 'Sector',
}

export const commodityFilterTypes = [
  CommodityFilterConstants.COMMODITY,
  FilterConstants.FACTOR,
  FilterConstants.PORTFOLIO,
  FilterConstants.STRATEGY,
];

export const equityGroupByConstants: (FilterConstants | EquityGroupByConstants)[] = Array.from(
  new Set([
    ...Object.values(EquityGroupByConstants),
    ...Object.values(FilterConstants).filter(
      (v) =>
        ![FilterConstants.SECTOR, FilterConstants.CURRENCY, FilterConstants.PORTFOLIO].includes(v as FilterConstants),
    ),
  ]),
).sort();

export enum TableLayerConstants {
  ASSET_CLASS = 'Asset Class',
  COUNTRY = 'Country',
  COMMODITY = 'Commodity',
  CURRENCY = 'Currency',
  RISK_CURRENCY = 'Currency of Risk',
  FACTOR = 'Factor',
  INSTRUMENT = 'Instrument',
  ISSUER_NAME = 'Issuer',
  ISSUER_TYPE = 'Issuer Type',
  LS = 'Long/Short',
  MATURITY = 'Maturity',
  PORTFOLIO = 'Portfolio',
  PRODUCT_TYPE = 'Product Type',
  PROVIDER = 'Provider',
  REGION = 'Region',
  SECTOR = 'Sector',
  STRATEGY = 'Strategy',
  UNDERLYING = 'Underlying',
  VOLATILITY_SHOCK = 'Volatility Shock',
}

export const creditGroupByConstants = Object.values(FilterConstants)
  .filter(
    (x): boolean =>
      ![
        FilterConstants.ASSET_CLASS,
        FilterConstants.CURRENCY,
        FilterConstants.RISK_CURRENCY,
        FilterConstants.SECTOR,
      ].includes(x),
  )
  .concat([TableLayerConstants.ISSUER_NAME, TableLayerConstants.ISSUER_TYPE] as unknown as FilterConstants[])
  .sort();

export const commodityTreeMapColors: { [commodity: string]: string } = {
  energy: '#1F8CAE',
  grains: '#92C47F',
  'precious-metals': '#F9C13A',
  'industrial-metals': '#A5A5A5',
  softs: '#AB842A',
  livestock: '#388D5F',
};

export const equityTreeMapColors: { [sector: string]: string } = {
  technology: '#245AEE',
  healthcare: '#9ACE93',
  energy: '#1F8CAE',
  industrials: '#173D4D',
  realEstate: '#0C1743',
  basicMaterials: '#706563',
  utilities: '#F7C742',
  financials: '#3A9D73',
  consumerNonCyclicals: '#9A9A9A',
  consumerCyclicals: '#FF7D00',
};

export const equitySpotLadderColors: { [volSpot: string]: string } = {
  volNeg10: '#92C47F',
  volNeg5: '#388D5F',
  volPos0: ApplicationDefaults.colors.Primary,
  volPos5: '#1F8CAE',
  volPos10: '#A5A5A5',
};

export enum VarMetrics {
  VAR_99 = 'VaR 3Y+1Y 99% 1M',
  CVAR_99 = 'CVaR 3Y+1Y 99% 1M',
  VAR_95 = 'VaR 3Y+1Y 95% 1M',
  CVAR_95 = 'CVaR 3Y+1Y 95% 1M',
  ESG_DISTRIBUTION = 'ESG Distribution',
}

export enum VarGrouping {
  ALLOCATED = 'Allocated',
  STANDALONE = 'Standalone',
}

export enum StressTestTypes {
  TRANSITIVE = 'transitive',
  HISTORICAL = 'historical',
  CUSTOM = 'custom',
}

export enum VarNotional {
  EXPOSURE_NOTIONAL = 'Exposure Notional',
  NOTIONAL = 'Notional',
  EQUIVALENT_DELTA_NOTIONAL = 'Equivalent Delta Notional',
  FACTOR_DISTRIBUTION = 'Factor Distribution',

  // For Position Page
  WEIGHT = 'Weight',
}

/**
 * These are ALWAYS percentage based, regardless of the X axis units
 */
export enum SpotLadderShocks {
  POS_0 = '+0',
}

export enum LadderTitles {
  SPOT = 'Spot',
  SPOT_VOL = 'Spot Vol',
  VOL_SPOT = 'Vol Spot',
  VOL = 'Vol',
}

export enum StressTestColumnTypes {
  TRANSITIVE_STRESS = 'TransitiveStress',
  HISTORICAL_STRESS = 'HistoricalStress',
}

/**
 * Components to get for each tab while in position mode
 * 'Ribbon' components are added during the getConstituentRiskData function.
 * only call CHART components here. Table components will be called by getTableComponents in ConstituentRiskTable.vue
 */
export const dataComponents: { [key in RiskTabs]: (keyof IConstituentRisk)[] } = {
  [RiskTabs.VAR]: ['VarCharts'],
  [RiskTabs.STRESS_TEST]: ['StressTest'],
  [RiskTabs.EQUITY]: ['EquityTreeMap', 'EquityBarChart', 'EquitySpotLadder'],
  [RiskTabs.INTEREST_RATE]: ['IRChart', 'IRSpotLadder'],
  [RiskTabs.CREDIT]: ['CreditChart'],
  [RiskTabs.FX]: ['FXChart', 'FXSpotLadder'],
  [RiskTabs.COMMODITY]: ['CommodityTreeMap', 'CommodityForward', 'CommoditySpotLadder'],
};

/**
 * Components to get for each tab while in history mode.
 * 'Ribbon' components are added during the getConstituentRiskData function.
 */
export const dataHistoryComponents: {
  [key in RiskTabs]: (keyof IConstituentRisk)[];
} = {
  [RiskTabs.VAR]: ['VarMetricTrack', 'ExposureMetricTrack'],
  [RiskTabs.STRESS_TEST]: ['StressTestMetricTrack'],
  [RiskTabs.EQUITY]: ['EquityMetricTrack'],
  [RiskTabs.INTEREST_RATE]: ['IRMetricTrack'],
  [RiskTabs.CREDIT]: ['CreditMetricTrack'],
  [RiskTabs.FX]: ['FXMetricTrack'],
  [RiskTabs.COMMODITY]: ['CommodityMetricTrack'],
};

export const updateTabs: { tabName: RiskTabs; newVal: boolean }[] = [
  { tabName: RiskTabs.VAR, newVal: true },
  { tabName: RiskTabs.EQUITY, newVal: true },
  { tabName: RiskTabs.INTEREST_RATE, newVal: true },
  { tabName: RiskTabs.CREDIT, newVal: true },
  { tabName: RiskTabs.FX, newVal: true },
  { tabName: RiskTabs.STRESS_TEST, newVal: true },
  { tabName: RiskTabs.COMMODITY, newVal: true },
];

export const historyTestOption = {
  type: StressTestTypes.TRANSITIVE,
  title: 'S&P 500 -10%',
  shock: -10,
};

export const customTests = [
  {
    type: StressTestTypes.TRANSITIVE,
    title: 'Oil -10%',
    shock: -10,
  },
  {
    type: StressTestTypes.TRANSITIVE,
    title: 'Gold -10%',
    shock: -10,
  },
  {
    type: StressTestTypes.TRANSITIVE,
    title: 'Silver -10%',
    shock: -10,
  },
  {
    type: StressTestTypes.TRANSITIVE,
    title: 'CSI 300 -10%',
    shock: -10,
  },
  {
    type: StressTestTypes.TRANSITIVE,
    title: 'FTSE 100 -10%',
    shock: -10,
  },
  {
    type: StressTestTypes.TRANSITIVE,
    title: 'FTSE Emerging -10%',
    shock: -10,
  },
];

export type StressTestDefaultItem = {
  reportField: string;
  stressTestDefaultShock: number;
  underlyingName: string;
  stressTestUnit: string;
  graphShockDescription: string;
  assetClass: string;
};

export interface IConstituentRiskOptions {
  riskMetrics: {
    [key in RiskTabProperName]: {
      [key in
        | RiskTableTabs
        | StressTestColumnTypes.HISTORICAL_STRESS
        | StressTestColumnTypes.TRANSITIVE_STRESS]: string[];
    };
  };
  segmentCustomOptions: {
    [key: string]: string[];
  };
  stressTestDefaults: StressTestDefaultItem[];
  asset: string[];
}

export interface CustomStressTest {
  name: string;
  id?: string;
  parameter: (StressTestDropdownOptionType | Record<string, never>)[];
}

export type StressTestDropdownType = {
  type: string;
  title: string;
  name?: string;
  shock?: number;
  // Used for custom stress test
  parameter?: (StressTestDropdownOptionType | Record<string, never>)[];
  optionsId?: string;
};

export type StressTestDropdownOptionType = {
  type: string;
  title: string;
  shock: number | null;
};

export enum RiskTableTabs {
  ASSET = 'Asset',
  VAR = 'VaR',
  EXPOSURE = 'Exposure',
  FACTOR = 'Factor',
  TRANSITIVE_STRESS = 'Transitive Stress',
  HISTORICAL_STRESS = 'Historical Stress',
  SENSITIVITY = 'Sensitivity',
  DV01_TENOR = 'DV01 by Tenor',
  CS01_TENOR = 'CS01 by Tenor',
  FX_DELTA = 'FX Delta',
  COMMODITY_DELTA = 'Delta by Month',
  COMMODITY_GAMMA = 'Gamma by Month',
  LADDER = 'Ladder',
  SPOT_VOL_LADDER = 'Spot/Vol Ladder',
  SPOT_VOL_NORMAL_LADDER = 'Spot Vol Normal Ladder',
  SPOT_VOL_BS_LADDER = 'Spot Vol Bs Ladder',
  CUSTOM = 'Custom',
}
