import useEnv from '@/composables/useEnv';
import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { AppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';

export const adminChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.ACCOUNT_MANAGEMENT,
    path: 'users',
    components: {
      default: () => import('@/views/admin/users/AdminUsers.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Users',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_USER,
    path: 'users/:id',
    components: {
      default: () => import('@/views/admin/users/AdminUserEdit.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit User',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TRACK_MANAGEMENT,
    path: 'strategies',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/strategies/AdminStrategies.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Strategies',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.BASKET_REQUEST,
    path: 'basket-request',
    components: {
      default: () => import('@/views/admin/AdminBasketRequestManagement.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Basket',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.TRACK_SYNC_JOBS,
    path: 'strategies/jobs',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/jobs/AdminTrackSyncJobs.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track Sync Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.TSE_PROVIDERS,
    path: 'strategies/providers',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/strategies/AdminProviders.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Providers',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CREATE_STRATEGY,
    path: 'strategies/new',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyCreate.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_STRATEGY,
    path: 'strategies/:id',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyEdit.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PRIVATE_TRACKS,
    path: 'private-tracks',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/private-tracks/AdminPrivateTracks.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Private Tracks',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_PRIVATE_TRACK,
    path: 'private-tracks/:id',
    components: {
      default: () => import('@/views/admin/private-tracks/AdminPrivateTrackView.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Private Tracks',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.INSTRUMENTS_MANAGEMENT,
    path: 'instruments',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsMetrics.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Metrics',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.FUTURES,
    path: 'instruments/futures',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsFuturesTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Futures',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.STOCKS,
    path: 'instruments/stocks',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsStocksTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Stocks',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.BONDS,
    path: 'instruments/bonds',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsBondsTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Bonds',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.FUNDS,
    path: 'instruments/funds',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsFundsTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Funds',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.ETFS,
    path: 'instruments/etfs',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsETFsTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | ETFs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.MARKET_INDICES,
    path: 'instruments/market-indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsMarketIndices.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Market Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CDS_INDICES,
    path: 'instruments/cds-indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsCdsIndicesTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | CDS Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_CDS_INDEX,
    path: 'instruments/cds-indices/:familyId',
    components: {
      default: () => import('@/views/admin/instruments/AdminCdsIndices.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CUSTOM_INDICES,
    path: 'instruments/custom-indices',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/instruments/AdminInstruments.vue'),
      default: () => import('@/views/admin/instruments/AdminInstrumentsCustomIndicesTable.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Custom Indices',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_CUSTOM_INDEX,
    path: 'instruments/custom-indices/:securityId',
    components: {
      default: () => import('@/views/admin/instruments/AdminCustomIndex.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  ...[
    { name: RouteName.VIEW_INSTRUMENT, path: 'instruments/:securityId' },
    { name: RouteName.VIEW_INSTRUMENT_POSITION, path: 'instruments/:securityId/position' },
    { name: RouteName.VIEW_INSTRUMENT_RISK, path: 'instruments/:securityId/risk' },
    { name: RouteName.VIEW_INSTRUMENT_OPTIONS, path: 'instruments/:securityId/options' },
    { name: RouteName.VIEW_INSTRUMENT_FORWARDS, path: 'instruments/:securityId/forwards' },
    { name: RouteName.VIEW_INSTRUMENT_SWAPS, path: 'instruments/:securityId/swaps' },
  ].map(({ name, path }) => ({
    name: name,
    path: path,
    components: {
      default: () => import('@/views/admin/instruments/AdminInspectSecurity.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  })),
  {
    name: RouteName.VIEW_FUTURE,
    path: 'instruments/futures/:specId',
    components: {
      default: () => import('@/views/admin/instruments/AdminFutures.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Instruments | Futures',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  ...(import.meta.env.VITE_CLIENT === 'Premialab'
    ? [
        {
          name: RouteName.CLIENT_REQUESTS,
          path: 'client-request',
          components: {
            default: () => import('@/views/admin/client-request/AdminClientRequest.vue'),
            sidebar: () => import('@/views/admin/AdminSideBar.vue'),
          },
          meta: {
            title: 'Admin | Client Requests',
            navbar: true,
            shouldAdminConsoleSidebarBeShown: true,
            hasAdminConsoleToolbar: false,
          },
        },
        {
          name: RouteName.EDIT_CLIENT_REQUEST_PROFILE,
          path: 'client-request/:clientRequestId',
          components: {
            'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
            default: () => import('@/views/admin/client-request/AdminClientRequestProfile.vue'),
            sidebar: () => import('@/views/admin/AdminSideBar.vue'),
          },
          meta: {
            title: 'Admin | Client Request Profile',
            navbar: true,
            shouldAdminConsoleSidebarBeShown: true,
            hasAdminConsoleToolbar: false,
          },
        },
        {
          name: RouteName.DATABASE,
          path: 'database',
          components: {
            'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
            default: () => import('@/views/admin/database/AdminDatabase.vue'),
            sidebar: () => import('@/views/admin/AdminSideBar.vue'),
          },
          meta: {
            title: 'Admin | Database',
            navbar: true,
            shouldAdminConsoleSidebarBeShown: true,
            hasAdminConsoleToolbar: true,
          },
        },
      ]
    : []),
  {
    name: RouteName.TEAM_MANAGEMENT,
    path: 'teams',
    components: {
      default: () => import('@/views/admin/team/AdminTeams.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Teams',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_TEAM,
    path: 'teams/:id',
    components: {
      default: () => import('@/views/admin/team/AdminTeamEdit.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Team',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.FILE_MANAGEMENT,
    path: 'files',
    components: {
      default: () => import('@/views/admin/files/index/AdminFiles.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Files',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_FILE,
    path: 'files/:fileContributionId',
    components: {
      default: () => import('@/views/admin/files/inspect/AdminInspectFile.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Files | Inspect File',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONSTITUENT_MANAGEMENT,
    path: 'constituents',
    components: {
      default: () => import('@/views/admin/constituents/index/AdminConstituents.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PROXY_CHECK,
    path: 'constituents/proxy-check',
    components: {
      default: () => import('@/views/admin/constituents/proxy-check/AdminConstituentsProxyCheck.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent | Proxy Check',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_CONSTITUENT,
    path: 'constituents/:id',
    components: {
      default: () => import('@/views/admin/constituents/overview/AdminConstituentInspect.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_FILES,
    path: 'constituents/:id/files',
    components: {
      default: () => import('@/views/admin/constituents/files/AdminConstituentInspectFiles.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Files',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_POSITIONS,
    path: 'constituents/:id/position',
    components: {
      default: () => import('@/views/admin/constituents/position/AdminConstituentInspectPosition.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Positions',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_POSITION,
    path: 'constituents/:id/position/:date',
    components: {
      default: () => import('@/views/admin/constituents/position/AdminConstituentInspectPosition.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Position',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT,
    path: 'risk',
    redirect: { name: RouteName.RISK_MANAGEMENT_RISK_PORTFOLIOS },
  },
  {
    name: RouteName.RISK_MANAGEMENT_RISK_PORTFOLIOS,
    path: 'risk/portfolios',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/risk/AdminRisk.vue'),
      default: () => import('@/views/admin/risk/AdminRiskPortfolio.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Risk Portfolios',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT_RPP_JOB_STATUS,
    path: 'risk/rpp-job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/risk/AdminRisk.vue'),
      default: () => import('@/views/admin/risk/AdminRiskJobStatus.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | RPP Job Status',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT_RISK_KPI,
    path: 'risk/risk-kpi',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/risk/AdminRisk.vue'),
      default: () => import('@/views/admin/risk/AdminRiskKpi.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Risk KPI',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT_RISK_VALIDATION,
    path: 'risk/risk-validation',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/risk/AdminRisk.vue'),
      default: () => import('@/views/admin/risk/AdminRiskValidation.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Risk Validation',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.API_KEYS,
    path: 'keys',
    components: {
      default: () => import('@/views/admin/api-key/AdminApiKey.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | API Keys',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.SETTINGS,
    path: 'settings',
    components: {
      default: () => import('@/views/admin/setting/AdminSetting.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Settings',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_CONFIG,
    path: 'perf-contribution',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfig.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Config',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_BACKFILL,
    path: 'perf-contribution/backfill',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionBackfill.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Backfill',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_GENERATED_DATA,
    path: 'perf-contribution/generated-data',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionGeneratedData.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Generated Data',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_JOB_STATUS,
    path: 'perf-contribution/job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionJobStatus.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Job Status',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.EDIT_PERFORMANCE_CONTRIBUTION,
    path: 'perf-contribution/:id',
    components: {
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfigForm.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Perf Contribution',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CREATE_PERFORMANCE_CONTRIBUTION_CONFIG,
    path: 'perf-contribution-create',
    components: {
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfigForm.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Perf Contribution',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PLATFORM_HEALTH,
    path: 'platform-health',
    components: {
      default: () => import('@/views/admin/platform-health/AdminPlatformHealth.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Platform Health',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.SUBSCRIPTIONS,
    path: 'subscriptions',
    components: {
      default: () => import('@/views/admin/subscriptions/AdminSubscriptions.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Subscriptions',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CUSTOM_REPORT,
    path: 'custom-report',
    redirect: { name: RouteName.CUSTOM_REPORT_JOB_STATUS },
  },
  {
    name: RouteName.CUSTOM_REPORT_JOB_STATUS,
    path: 'custom-report/job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/custom-report/AdminCustomReport.vue'),
      default: () => import('@/views/admin/custom-report/AdminCustomReportJobs.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Custom Report Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CUSTOM_REPORT_RISK_EXCEL_JOB_STATUS,
    path: 'custom-report/risk-excel-job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/custom-report/AdminCustomReport.vue'),
      default: () => import('@/views/admin/custom-report/AdminCustomReportRiskExcelJobs.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Custom Report Risk Excel Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.QA,
    path: 'qa',
    redirect: { name: RouteName.TRACK_QA },
  },
  {
    name: RouteName.TRACK_QA,
    path: 'qa/track',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/qa/AdminQA.vue'),
      default: () => import('@/views/admin/qa/AdminTrackQA.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track QA',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CONSTITUENT_QA,
    path: 'qa/constituent',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/qa/AdminQA.vue'),
      default: () => import('@/views/admin/qa/AdminConstituentQA.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent QA',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_TRACK_QA,
    path: 'qa/track/:workflowId/:runId/track',
    components: {
      default: () => import('@/views/admin/qa/AdminTrackQAInspect.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track QA',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_CONSTITUENT_QA,
    path: 'qa/constituent/:workflowId/:runId/track',
    components: {
      default: () => import('@/views/admin/qa/AdminConstituentQAInspect.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent QA',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.REPORT_FIELDS,
    path: 'report-fields',
    components: {
      default: () => import('@/views/admin/report-fields/AdminReportFields.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Report Fields',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_REPORT_FIELD,
    path: 'report-fields/:fieldId',
    components: {
      default: () => import('@/views/admin/report-fields/AdminReportFieldInspect.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Report Field',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EXCEL_JOBS,
    path: 'jobs/excel',
    components: {
      default: () => import('@/views/admin/jobs/AdminExcelJobs.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Excel Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONTENT_MANAGEMENT,
    path: 'articles',
    components: {
      default: () => import('@/views/admin/articles/AdminContentManagement.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Content Management',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },

  {
    name: RouteName.PDF_MANAGEMENT_ADMIN,
    path: 'pdf-management',
    components: {
      default: () => import('@/whitelabel/hsbc/views/admin/strategies/AdminStrategiesPDF.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | PDF Management',
      permission: [UserPermission.ADMIN],
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
      isMainContentContainerFluid: true,
    },
    beforeEnter: (to, from, next) => {
      const { isHSBCEnvironment } = useEnv();
      if (isHSBCEnvironment) {
        next();
        return;
      }
      next({ name: RouteName.NOT_FOUND });
    },
  },
  {
    name: RouteName.PLATFORM_ANALYTICS,
    path: 'platform-analytics',
    components: {
      default: () => import('@/views/admin/AdminPlatformAnalytics.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Platform Analytics',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.MIGRATIONS_ADMIN,
    path: 'migrations',
    components: {
      default: () => import('@/views/admin/migrations/AdminMigrations.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Migrations',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.INSPECT_MIGRATION,
    path: 'migrations/:applicationName',
    components: {
      default: () => import('@/views/admin/migrations/AdminInspectMigration.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Migration',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.MUTATIONS_ADMIN,
    path: 'mutations',
    components: {
      default: () => import('@/views/admin/migrations/AdminMutations.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Mutations',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.INSPECT_MUTATION,
    path: 'mutations/:createdAt/:applicationName',
    components: {
      default: () => import('@/views/admin/migrations/AdminInspectMutation.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Mutation',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
];

export const AdminRouter: RouteConfig[] = [
  {
    path: '/console',
    name: RouteName.CONSOLE,
    components: {
      default: AppMain,
    },
    redirect: () => {
      const { isHSBCEnvironment } = useEnv();
      return {
        name: isHSBCEnvironment ? RouteName.TRACK_MANAGEMENT : RouteName.TEAM_MANAGEMENT,
      };
    },
    meta: {
      title: 'Admin',
      permission: [UserPermission.ADMIN],
    },
    children: adminChildrenRouter,
  },
];
