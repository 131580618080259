import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { FactorConstants } from '@/constants/FactorConstants';

export enum PortfolioType {
  PORTFOLIO = 'portfolio',
  CONSTITUENT = 'constituent',
  START_FRESH = 'start_fresh',
  EQUITY_BASKET = 'equity_basket',
  QIS_BASKET = 'qis_basket',
}

export interface CorrelationMatrixDatum {
  value: number;
  x: string;
  y: string;
}

export interface IPortfolioTrack {
  correlation: [CorrelationMatrixDatum, CorrelationMatrixDatum][];

  incrementalReturn: {
    [code: string]: number;
  };
  incrementalVar: {
    [code: string]: number;
  };
  incrementalVolatility: {
    [code: string]: number;
  };

  monthlyRtnDates: string[];
  monthlyRtn: number[];

  yearlyRtnDates: string[];
  yearlyRtn: number[];

  timeseriesDates: string[];
  timeseriesEquityVariation: number[];
  timeseriesRollingDrawDowns: number[];

  timeseriesWeights: { [strategyCode: string]: number }[];
  timeseriesLeverage: [number, number][];

  weightAssetClass: {
    [AssetClassConstants.COMMODITY]: number;
    [AssetClassConstants.CREDIT]: number;
    [AssetClassConstants.EQUITY]: number;
    [AssetClassConstants.FIXED_INCOME]: number;
    [AssetClassConstants.FOREIGN_EXCHANGE]: number;
    [AssetClassConstants.MULTI_ASSETS]: number;
  };

  weightFactor: {
    [FactorConstants.CARRY]: number;
    [FactorConstants.LOW_VOL]: number;
    [FactorConstants.MOMENTUM]: number;
    [FactorConstants.MULTI]: number;
    [FactorConstants.QUALITY]: number;
    [FactorConstants.SIZE]: number;
    [FactorConstants.VALUE]: number;
    [FactorConstants.VOLATILITY]: number;
  };
}

export enum ExposureMode {
  LONG_SHORT = 'Long / Short',
  GROSS = 'Gross',
  NET = 'Net',
}

export enum MonthlyReturnDistributionScaleOption {
  LINEAR_SCALE = 'Linear Scale',
  LOGARITHMIC_MODE = 'Logarithmic Scale',
}

export enum MonthlyReturnDistributionGraphMode {
  /**
   * Series shown:
   * - distribution of the base index (be it portfolio or strategy)
   * - distribution of the benchmark
   */
  FACTSHEET_VANILLA_CASE = 'FactSheet Vanilla Case',

  /**
   * Series shown:
   * - distribution of the base index (be it portfolio or strategy)
   * - distribution of the active return track
   */
  FACTSHEET_ACTIVE_RETURN = 'FactSheet Active Return',

  /**
   * Series shown based on user selection:
   * - default: distribution of the base index (be it portfolio or strategy), one for each regime
   * - distribution of the benchmark (be it portfolio or strategy), one for each regime
   */
  FACTSHEET_MARKET_REGIME = 'FactSheet Market Regime',

  /**
   * Series shown:
   * - distribution of the base index (be it portfolio or strategy)
   * - distribution of the regression track
   */
  FACTOR_DECOMP_VANILLA = 'Factor Decomposition Vanilla Case',

  /**
   * Series shown:
   * - distribution of active return track
   * - distribution of the regression track
   */
  FACTOR_DECOMP_ACTIVE_RETURN = 'Factor Decomposition Active Return',

  /**
   * series shown based on user selection:
   * - default: distribution of the base index (be it portfolio or strategy), one for each regime
   * - distribution of the regression track, one for each regime
   */
  FACTOR_DECOMP_MARKET_REGIME = 'Factor Decomposition Market Regime',
}
