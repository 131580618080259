import { PluginObject } from 'vue';
import router from '@/router';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';

function enableApm() {
  // Disable apm for bofa for pentest report
  // See WAA-6678
  if (import.meta.env.VITE_CLIENT === 'BofA') {
    return false;
  }

  return import.meta.env.PROD && (import.meta.env.VITE_STAGE === 'prod' || import.meta.env.VITE_STAGE === 'uat');
}

export const apmPlugin: PluginObject<void> = {
  install(vue) {
    if (!enableApm()) {
      return;
    }

    vue.use(ApmVuePlugin, {
      router,
      config: {
        serviceName: 'spa-premialab',
        environment: import.meta.env.VITE_STAGE,
        secretToken: import.meta.env.VITE_APM_TOKEN,
        serverUrl: 'https://3da28fac89004fcc98b584edb216e03a.apm.eu-west-1.aws.cloud.es.io:443',
        breakdownMetrics: true,
      },
      captureErrors: true,
    });
  },
};
