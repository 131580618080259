<template>
  <div class="d-print-none">
    <span
      v-if="showScreenSize"
      class="position-fixed p-3 d-print-none"
      style="z-index: 999; background: white; right: 0; top: 0"
    >
      {{ appScreenSizeText }}
    </span>
    <div
      id="screen-xs"
      v-b-visible="handleXsVisible"
      class="d-block d-sm-none scroll-bar-ruler"
    />
    <div
      id="screen-sm"
      v-b-visible="handleSmVisible"
      class="d-none d-sm-block d-md-none scroll-bar-ruler"
    />
    <div
      id="screen-md"
      v-b-visible="handleMdVisible"
      class="d-none d-md-block d-lg-none scroll-bar-ruler"
    />
    <div
      id="screen-lg"
      v-b-visible="handleLgVisible"
      class="d-none d-lg-block d-xl-none scroll-bar-ruler"
    />
    <div
      id="screen-xl"
      v-b-visible="handleXlVisible"
      class="d-none d-xl-block d-xxl-none scroll-bar-ruler"
    />
    <div
      id="screen-xxl"
      v-b-visible="handleXxlVisible"
      class="d-none d-xxl-block scroll-bar-ruler"
    />
  </div>
</template>

<script lang="ts">
import { useIsPdf } from '@/composables/usePdf';
import { SettingsModule } from '@/store/barrel';
import { ScreenSizes } from '@/store/modules/settings';
import { useWindowSize } from '@vueuse/core';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'ScreenSizeComponent',
  setup() {
    const { width, height } = useWindowSize();
    const screenSize = ref(ScreenSizes.xs);
    const appScreenSize = computed(() => SettingsModule.screenSize);
    const appScreenSizeText = computed(
      () => `${width.value} x ${height.value} - ${SettingsModule.screenSize} - ${SettingsModule.screenSizeInt}`,
    );

    watch(screenSize, (newVal) => {
      if (newVal !== appScreenSize.value) SettingsModule.SetScreenSize(newVal);
    });

    const handleXsVisible = (isVisible: boolean) => {
      if (isVisible) screenSize.value = ScreenSizes.xs;
    };
    const handleSmVisible = (isVisible: boolean) => {
      if (isVisible) screenSize.value = ScreenSizes.sm;
    };
    const handleMdVisible = (isVisible: boolean) => {
      if (isVisible) screenSize.value = ScreenSizes.md;
    };
    const handleLgVisible = (isVisible: boolean) => {
      if (isVisible) screenSize.value = ScreenSizes.lg;
    };
    const handleXlVisible = (isVisible: boolean) => {
      if (isVisible) screenSize.value = ScreenSizes.xl;
    };
    const handleXxlVisible = (isVisible: boolean) => {
      if (isVisible) screenSize.value = ScreenSizes.xxl;
    };

    const isPdf = useIsPdf();

    const showScreenSize = computed(
      () => (import.meta.env.DEV || import.meta.env.VITE_STAGE === 'dev') && !isPdf.value,
    );

    return {
      showScreenSize,
      handleXsVisible,
      handleSmVisible,
      handleMdVisible,
      handleLgVisible,
      handleXlVisible,
      handleXxlVisible,
      appScreenSize,
      appScreenSizeText,
    };
  },
});
</script>
