export enum PerfContribConfigStatusConstants {
  /**
   * @deprecated
   * Pending to be replaced with QA_GATE_1
   */
  PENDING = 'PENDING',

  TO_PARSE = 'TO_PARSE',
  QA_GATE_1 = 'QA_GATE_1',
  VALIDATED = 'VALIDATED',
  DECOMMISSIONED = 'DECOMMISSIONED',

  /**
   * Computed statuses
   */
  MISSING_INTRADAY = 'MISSING_INTRADAY',
  MISSING_EOD = 'MISSING_EOD',
  RESTRICTED = 'RESTRICTED',
  RELEASED = 'RELEASED',
  RELEASED_WITH_GAPS = 'RELEASED_WITH_GAPS',
  NA = 'N/A',

  /**
   * @deprecated
   * Adding until the data is cleaned
   */
  TODO = 'TODO',
}
