import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { cloneDeep, union, uniq } from 'lodash';
import {
  deleteCustomStressTest,
  getConstituentRiskData,
  getConstituentRiskOptions,
  getConstituentRiskRibbon,
  getCustomStressTest,
  updateCustomStressTest,
  uploadCustomStressTest,
} from '@/api-v2/web/risk-dashboard';
import {
  EquityGroupByConstants,
  IConstituentRiskOptions,
  RiskTabs,
  UnitConstants,
  FilterConstants,
  TableLayerConstants,
  StressTestDropdownType,
  CustomStressTest,
} from '@/types/analytics/ConstituentRisk';
import {
  IConstituentRibbonQuery,
  IConstituentRisk,
  RiskDashboardTableComponentsConstants,
} from '@/types/IConstituentRisk';
import { DateTime } from 'luxon';
import { IConstituentRiskTable } from '@/types/IConstituentRiskTable';
import { MetricConstants } from '@/constants/MetricConstants';
import usePortfolioTree from '@/composables/usePortfolioTree';
import useStrategyMask from '@/composables/useStrategyMask';

@Module({ dynamic: true, store, name: 'ConstituentRisk', namespaced: true })
class ConstituentRisk extends VuexModule {
  public constituentRiskData: IConstituentRisk = {
    Dates: null,
    RiskTable: null,
    Ribbon: null,
    CommodityTreeMap: null,
    CommodityForward: null,
    CommoditySpotLadder: null,
    CommodityTableDelta: null,
    CommodityTableGamma: null,
    CommodityMetricTrack: null,
    CommodityLadderTable: null,
    EquityTreeMap: null,
    EquityBarChart: null,
    EquitySpotLadder: null,
    EquityMetricTrack: null,
    EquityLadderTable: null,
    VarCharts: null,
    VarMetricTrack: null,
    ExposureMetricTrack: null,
    StressTest: null,
    StressTestMetricTrack: null,
    FXChart: null,
    FXMetricTrack: null,
    FXSpotLadder: null,
    FXTable: null,
    FXLadderTable: null,
    IRChart: null,
    IRMetricTrack: null,
    IRSpotLadder: null,
    IRTable: null,
    IRNormalLadderTable: null,
    IRBsLadderTable: null,
    CreditChart: null,
    CreditMetricTrack: null,
    CreditTable: null,
  };
  public optionsData: IConstituentRiskOptions | null = null;
  public componentsList: string[] = [];

  public positionDate: DateTime | null = null;
  public rollingDate: DateTime | null = null;
  public fieldsToCall: { tableComponent: RiskDashboardTableComponentsConstants; fields: string[] | undefined } = {
    tableComponent: RiskDashboardTableComponentsConstants.RiskTable,
    fields: undefined,
  };

  public cachedFields: string[] = [];

  public isHistoryMode = false;

  public hasPageOptionsChanged: { [key in RiskTabs]: boolean } = {
    [RiskTabs.VAR]: false,
    [RiskTabs.STRESS_TEST]: false,
    [RiskTabs.EQUITY]: false,
    [RiskTabs.INTEREST_RATE]: false,
    [RiskTabs.CREDIT]: false,
    [RiskTabs.FX]: false,
    [RiskTabs.COMMODITY]: false,
  };

  public customStressTests: CustomStressTest[] = [];

  @Action({ rawError: true })
  public async GetConstituentRiskOptions({ slug, date }: { slug: string; date: string }): Promise<void> {
    const options = await getConstituentRiskOptions({
      slug,
      params: { date },
    });

    this.GET_CONSTITUENT_RISK_OPTIONS({ options });
  }

  @Mutation
  private GET_CONSTITUENT_RISK_OPTIONS({ options }: { options: IConstituentRiskOptions | null }): void {
    this.optionsData = options;
  }

  /**
   * Sets the constituent data in their respective variables.
   * @param slug Portfolio slug name
   * @param component Array of data components to receive
   * @param portfolioTreeId treeId of select subportfolio
   * @param date Portfolio position date
   * @param fields Portfolio fields chosen by options
   * @param rollingDate Reference date
   * @param unit Can either be % or $
   * @param exposureType Portfolio exposure chosen by options
   * @param equityMetric Metric to use in the calculation (Delta, Gamma, Vega)
   * @param equityFilterLevel1 First filter level
   * @param equityFilterLevel2 Second filter level
   * @param commodityMetric Metric to use in the calculation (Delta, Gamma, Vega)
   * @param varMetric1 Var item to fetch
   * @param varMetric1GroupBy Var group by for allocated
   * @param varMetric2 Exposure item to fetch
   * @param ribbonFieldNames List of metrics for the ribbon
   * @param stressTestOptions Dictionary of stress tests and their shocks
   * @param stressTestHistoryOption Stress test to use for history mode
   * @param stressTestFilter Filter
   * @param fxFilter Filter
   * @param trackSegment Time Chart should be calculated at the portfolio or strategy level
   * @param basisPoints Basis Point to use for the ribbon and charts of Credit and Interest Rate
   * @param riskTableLayers List of table layers
   * @param filterNullQuantityPosition Boolean to filter out positions with null or 0 values
   */
  @Action({ rawError: true })
  public async GetConstituentRiskData({
    slug,
    component,
    portfolioTreeId,
    analysisCodes,
    date,
    fields,
    rollingDate,
    unit,
    equityMetric,
    equityFilterLevel1,
    equityFilterLevel2,
    commodityMetric,
    varMetric1,
    varMetric1GroupBy,
    varMetric2,
    ribbonFieldNames,
    stressTestOptions,
    stressTestHistoryOption,
    stressTestFilter,
    fxFilter,
    trackSegment,
    basisPoints,
    riskTableLayers,
    filterNullQuantityPosition,
  }: {
    slug: string;
    component: string[];
    portfolioTreeId: string;
    /**
     * Replaces `suspendedForRisk` in the portfolioTreeDraft. Query will only be run using codes in this property
     */
    analysisCodes: string[];
    date: string;
    fields?: string[];
    rollingDate?: string;
    unit?: UnitConstants;
    equityMetric?: MetricConstants;
    equityFilterLevel1?: EquityGroupByConstants | FilterConstants;
    equityFilterLevel2?: EquityGroupByConstants | FilterConstants;
    commodityMetric?: MetricConstants;
    varMetric1?: string;
    varMetric1GroupBy?: FilterConstants;
    varMetric2?: string;
    ribbonFieldNames?: string[];
    stressTestOptions?: StressTestDropdownType[];
    stressTestHistoryOption?: StressTestDropdownType;
    stressTestFilter?: FilterConstants;
    fxFilter?: FilterConstants;
    trackSegment?: string;
    basisPoints?: number;
    riskTableLayers?: TableLayerConstants[];
    filterNullQuantityPosition?: boolean;
  }): Promise<void> {
    let data: IConstituentRisk | null = null;

    data = await getConstituentRiskData(
      slug,
      component,
      portfolioTreeId,
      analysisCodes,
      date,
      fields,
      rollingDate,
      unit,
      equityMetric,
      equityFilterLevel1,
      equityFilterLevel2,
      commodityMetric,
      varMetric1,
      varMetric1GroupBy,
      varMetric2,
      ribbonFieldNames,
      stressTestOptions,
      stressTestHistoryOption,
      stressTestFilter,
      fxFilter,
      trackSegment,
      basisPoints,
      riskTableLayers,
      filterNullQuantityPosition,
    );

    this.SET_CONSTITUENT_RISK_COMPONENTS({ components: Object.keys(data) });
    this.SET_CONSTITUENT_RISK_DATA({ data });
    if (fields) {
      this.SET_CACHED_FIELD(fields);
    }
  }

  /**
   * Fetches and sets the ribbon
   */
  @Action({ rawError: true })
  public async GetConstituentRibbon(query: IConstituentRibbonQuery): Promise<void> {
    const ribbon = await getConstituentRiskRibbon(query);
    this.SET_CONSTITUENT_RISK_COMPONENTS({ components: ['Ribbon'] });
    this.SET_CONSTITUENT_RISK_DATA({ data: { Ribbon: ribbon } });
  }

  /**
   * Create a list of components that have already been fetched.
   */
  @Mutation
  private SET_CONSTITUENT_RISK_COMPONENTS({ components, clear }: { components: string[]; clear?: boolean }): void {
    this.componentsList = union(this.componentsList, components);
    if (clear) this.componentsList = [];
  }

  /**
   * Specific data component that needs to be updated
   */
  @Action({ rawError: true })
  public async SetConstituentRiskData({ data }: { data: Partial<IConstituentRisk> | null }): Promise<void> {
    this.SET_CONSTITUENT_RISK_DATA({ data });
  }

  @Mutation
  private SET_CONSTITUENT_RISK_DATA({ data }: { data: Partial<IConstituentRisk> | null }): void {
    const { portfolioName, itemUnderAnalysis } = usePortfolioTree();
    if (!data) {
      // When global variables are changed, we want to reset the constituentRiskData to its original form
      Object.keys(this.constituentRiskData).forEach((component) => {
        this.constituentRiskData[component as keyof IConstituentRisk] = null;
      });
      return;
    }

    // For the 'RiskTable' we want to replace the matching existing columns with the new columns
    // Or add the data for the new columns
    if (Object.keys(data).includes('RiskTable')) {
      const newTableComponent = this.constituentRiskData.RiskTable as IConstituentRiskTable;
      if (newTableComponent && data.RiskTable) {
        Object.keys(newTableComponent).forEach((key) => {
          newTableComponent[key].metrics = {
            ...newTableComponent[key].metrics,
            ...(data.RiskTable as IConstituentRiskTable)[key].metrics,
          };
          newTableComponent[key].statics = {
            ...newTableComponent[key].statics,
            ...(data.RiskTable as IConstituentRiskTable)[key].statics,
          };
        });
        return;
      }
    }

    // For 'FXTable' we want to sort the metrics in the same order as currencies in FXChart
    // which is from highest to lowest in total and remove the currencies with 0 delta
    if (Object.keys(data).includes('FXTable')) {
      const fxTableData = data.FXTable;

      const { getStrategyMask } = useStrategyMask();

      if (fxTableData) {
        // get the top row with name of the portfolio/strategy or 'Strategy' or 'Subtotal'
        const analysisName =
          Object.keys(fxTableData).find(
            (key) =>
              key === portfolioName.value ||
              (itemUnderAnalysis.value && key === getStrategyMask(itemUnderAnalysis.value)) ||
              key === 'Strategy' ||
              key === 'Subtotal',
          ) ?? '';
        const totalRowMetrics = fxTableData[analysisName].metrics;

        // remove currency of portfolio and currencies with 0 delta
        const currencies = Object.keys(totalRowMetrics)
          .filter((key) => totalRowMetrics[key] !== 0 && totalRowMetrics[key] !== null && key !== 'Total')
          .sort((a, b) => (totalRowMetrics[b] ?? 0) - (totalRowMetrics[a] ?? 0));

        // remove duplicates in currencies
        const orderedCurrencies = [...currencies, 'Total'];

        // sort metrics in fxTableData by descending order of currencies
        const sortedFxTableData: IConstituentRiskTable = {};
        for (const [key, value] of Object.entries(fxTableData)) {
          const sortedMetrics: { [x: string]: number | null } = {};
          for (const currency of orderedCurrencies) {
            sortedMetrics[currency] = value.metrics[currency];
          }
          sortedFxTableData[key] = { metrics: sortedMetrics, statics: value.statics };
        }

        data.FXTable = sortedFxTableData;
      }
    }

    // Replace the matching existing data with the new data
    // Or add the data for the new components
    this.constituentRiskData = {
      ...this.constituentRiskData,
      ...data,
    };
  }

  /**
   * Set position date.
   */
  @Mutation
  private SET_AS_OF_DATE(date: DateTime | null): void {
    this.positionDate = Object.freeze(date);
  }

  /**
   * Set position dat
   * And cleans up position date when value is null after new portfolio selection to prevent a bad api call.
   */
  @Action({ rawError: true })
  public async SetAsOfDate({ date }: { date: DateTime | null }): Promise<void> {
    this.SET_AS_OF_DATE(date);
  }

  /**
   * Set reference date.
   */
  @Mutation
  private SET_ROLLING_DATE(date: DateTime | null): void {
    this.rollingDate = Object.freeze(date);
  }

  /**
   * Sets the rolling date after user selection from ConstituentRiskCalendar.
   * @param date Selected rolling date
   */
  @Action({ rawError: true })
  public async SetRollingDate({ date }: { date: DateTime | null }): Promise<void> {
    this.SET_ROLLING_DATE(date);
  }

  /**
   * For each tab, change its status to the 'newVal' and update the variable.
   */
  @Mutation
  private SET_HAS_PAGE_OPTIONS_CHANGED({
    newVal,
    resetAll,
  }: {
    newVal?: { tabName: RiskTabs; newVal: boolean }[];
    resetAll?: boolean;
  }): void {
    const clone = cloneDeep(this.hasPageOptionsChanged);
    if (resetAll) {
      Object.keys(clone).forEach((element) => {
        clone[element as RiskTabs] = true;
      });
    } else if (newVal) {
      newVal.forEach((element): void => {
        clone[element.tabName] = element.newVal;
      });
    }
    this.hasPageOptionsChanged = clone;
  }

  /**
   * Determines when the constituent risk data API should be triggered.
   * Should be triggered on new portfolio slug, new date, new unit.
   * @param newVal Object array that includes the tabName and its new boolean value to determine if the API should be used
   */
  @Action({ rawError: true })
  public async SetHasPageOptionsChanged({
    newVal,
    resetAll,
  }: {
    newVal?: { tabName: RiskTabs; newVal: boolean }[];
    resetAll?: boolean;
  }): Promise<void> {
    this.SET_HAS_PAGE_OPTIONS_CHANGED({ newVal, resetAll });
    if (resetAll) {
      // Reset all store items to original form
      this.SET_CLEAR_CACHED_FIELD();
      this.SET_CONSTITUENT_RISK_COMPONENTS({ components: [], clear: true });
      this.SET_CONSTITUENT_RISK_DATA({ data: null });
    }
  }

  @Mutation
  private TOGGLE_HISTORY_MODE(shouldTurnOnHistory: boolean): void {
    this.isHistoryMode = shouldTurnOnHistory;
  }
  /**
   * Saves the constituent risk preferences as a cookie every time they are changed
   */
  @Action({ rawError: true })
  public async ToggleHistoryMode(shouldTurnOnHistory: boolean): Promise<void> {
    this.TOGGLE_HISTORY_MODE(shouldTurnOnHistory);
  }

  @Action({ rawError: true })
  public async SetClearCachedFields(): Promise<void> {
    this.SET_CLEAR_CACHED_FIELD();
  }
  /**
   * List of fields which have previously been called
   * @param fields new fields to cach
   */
  @Mutation
  private SET_CLEAR_CACHED_FIELD(): void {
    this.cachedFields = [];
  }
  /**
   * List of fields which have previously been called
   * @param fields new fields to cach
   */
  @Mutation
  private SET_CACHED_FIELD(fields: string[]): void {
    this.cachedFields = uniq(this.cachedFields.concat(fields));
  }

  @Action({ rawError: true })
  public async GetCustomStressTests(): Promise<void> {
    const options = await getCustomStressTest();

    this.GET_CUSTOM_STRESS_TESTS({ options });
  }

  @Mutation
  private GET_CUSTOM_STRESS_TESTS({ options }: { options: CustomStressTest[] }): void {
    this.customStressTests = options;
  }

  @Action({ rawError: true })
  public async SetCustomStressTests(body: CustomStressTest): Promise<void> {
    await uploadCustomStressTest(body);
  }

  @Action({ rawError: true })
  public async UpdateCustomStressTests(params: { attributeId: string; body: CustomStressTest }): Promise<void> {
    await updateCustomStressTest(params);
  }

  @Action({ rawError: true })
  public async DeleteCustomStressTests(attributeId: string): Promise<void> {
    await deleteCustomStressTest(attributeId);
  }
}

export const ConstituentRiskModule = getModule(ConstituentRisk);
