// this is reused in both `./main.ts` and the cypress component tests
// for more info: https://docs.cypress.io/app/component-testing/styling-components#Rules-for-Setting-Up-Your-Styles
import '@/styles/fonts.ts';
import '@/styles/theme.scss';

import Vue from 'vue';
import Component from 'vue-class-component';

import { bootstrapPlugin } from '@/plugins/bootstrap';
import { fontAwesomePlugin } from '@/plugins/fontAwesome';
import { isoCountryPlugin } from '@/plugins/isoCountries';
import { i18nPlugin } from './i18n';
import { focusOnInsertPlugin } from '@/plugins/focusOnInsert';

import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';
import { AxiosError } from 'axios';

import StandardButton from '@/components/standard-components/StandardButton.vue';
import StandardModal from '@/components/standard-components/StandardModal.vue';
import StandardFormDropdown from '@/components/standard-components/StandardFormDropdown.vue';
import HelpPopover from '@/components/standard-components/HelpPopover.vue';
import SectionTitle from '@/components/standard-components/SectionTitle.vue';

Vue.use(bootstrapPlugin);
Vue.use(fontAwesomePlugin);
Vue.use(isoCountryPlugin);
Vue.use(i18nPlugin);
Vue.use(focusOnInsertPlugin);

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave']);

Vue.component('StandardButton', StandardButton);
Vue.component('StandardModal', StandardModal);
Vue.component('StandardFormDropdown', StandardFormDropdown);
Vue.component('HelpPopover', HelpPopover);
Vue.component('SectionTitle', SectionTitle);

Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error) => {
        // Retry up to 10 times if we receive a 504 Gateway Timeout
        if (((error as AxiosError).response?.status as number) === 504) {
          if (failureCount >= 10) return false;
          return true;
        }

        if (failureCount >= 3) return false;
        if (((error as AxiosError).response?.status as number) >= 500) return false;
        return true;
      },
      staleTime: 20 * 1000,
    },
  },
});
Vue.use(VueQueryPlugin, {
  queryClient,
});
