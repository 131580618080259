/**
 * Enum defining permissions from user tools and team addon.
 */
export enum UserPermission {
  API = 'user:api',
  UNBLOCK = 'user:unblock',
  PLATFORM = 'user:platform',
  RESEARCH = 'user:research',
  ADMIN = 'user:admin',
  READ_ONLY_PLATFORM = 'user:read_only_platform',
  /**
   * Used for white-labels for super admin access
   */
  ADMIN_SUPER_ADMIN = 'user:admin.super_admin',
  ADMIN_GENERATE_BEARER_TOKEN = 'user:admin.generate_bearer_token',
  ADMIN_SEARCH_STRATEGIES_EXACT_MATCH = 'user:admin.search_strategies_exact_match',
  IMPERSONATE = 'user:impersonate',
  WARN_IMPERSONATE = 'user:impersonate.warn',

  STRATEGY = 'user:strategy',
  STRATEGY_PDF = 'user:strategy.pdf',
  STRATEGY_EXCEL = 'user:strategy.excel',

  PORTFOLIO = 'user:portfolio',
  PORTFOLIO_PDF = 'user:portfolio.pdf',
  PORTFOLIO_EXCEL = 'user:portfolio.excel',

  PCA = 'user:pca',
  PCA_PDF = 'user:pca.pdf',
  PCA_EXCEL = 'user:pca.excel',

  REGRESSION = 'user:regression',
  REGRESSION_PDF = 'user:regression.pdf',
  REGRESSION_EXCEL = 'user:regression.excel',

  CONSTITUENT = 'user:constituent',
  CONSTITUENT_PDF = 'user:constituent.pdf',
  CONSTITUENT_EXCEL = 'user:constituent.excel',

  DEFAULT = 'user:default',
  DISCOVER = 'user:discover',
  MORNING_STAR = 'user:morningstar',
  READ_ONLY = 'user:readonly',
  IMPERSONATE_TEAM = 'user:impersonate_team',

  /**
   * Swap Details access rights from team addon permission
   */
  SWAP_DETAILS = 'user:swap_details',
  SWAP_DETAILS_EXCEL = 'user:swap_details:excel',

  /**
   * Drop Zone access rights from team addon permission
   */
  DROP_ZONE = 'user:dropzone',
  DROP_ZONE_PORTFOLIO_SNAPSHOT = 'user:dropzone:portfolio_snapshot',
  DROP_ZONE_CUSTOM_WEIGHTING = 'user:dropzone:custom_weighting',
  DROP_ZONE_PRIVATE_TRACKS = 'user:dropzone:private_tracks',

  /**
   * Strategy Performance access rights from team addon permission
   */
  STRATEGY_PERFORMANCE = 'user:strategy:performance',
  STRATEGY_PERFORMANCE_CONTRIBUTION = 'user:strategy:performance:contribution',
  STRATEGY_PERFORMANCE_RECONCILIATION = 'user:strategy:performance:reconciliation',
  STRATEGY_PERFORMANCE_PDF = 'user:strategy:performance:pdf',

  /**
   * API will grant a high level basket access, and then sub access to Equity and QIS baskets
   * This will future proof for new types of baskets that need to be added
   */
  BASKET = ADMIN,
  EQUITY_BASKET = ADMIN,
  QIS_BASKET = ADMIN,

  // Request QIS access rights from team addon permission
  REQUEST_QIS = 'user:request_qis',
}
