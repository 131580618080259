<template>
  <b-row class="mb-4">
    <b-col class="d-flex align-items-center">
      <icon
        class="ml-n4 mr-1"
        icon="link"
        :style="{ opacity: shouldShowIcon ? 1 : 0 }"
      />
      <h5 class="mb-0">
        <dt class="font-weight-500">
          <b-link
            v-b-tooltip.hover="tooltipText"
            :href="url"
            class="text-primary text-decoration-none mb-4"
            @mouseover="shouldShowIcon = true"
            @mouseleave="shouldShowIcon = false"
            @click.prevent="copyLink()"
          >
            {{ title }}
          </b-link>
        </dt>
      </h5>
    </b-col>

    <b-col class="d-flex justify-content-end">
      <b-button
        v-for="category of categories"
        :key="category"
        data-testid="filtered-category"
        :variant="shownCategories.includes(category) ? 'solid-hover-info' : 'outline-info'"
        class="ml-2"
        size="sm"
        @click="$emit('toggle-category', category)"
      >
        {{ category }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue';
import { GlossaryCategory } from '@/types/glossary';
import { useClipboard } from '@vueuse/core';
import useTranslation from '@/composables/useTranslation';

export default defineComponent({
  name: 'GlossaryItemHeader',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    categories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
    },
    shownCategories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
    },
  },
  emits: {
    'toggle-category': (_val: GlossaryCategory) => true,
  },
  setup(props) {
    const { translate } = useTranslation();

    const shouldShowIcon = ref(false);
    const url = computed(() => {
      return `${window.location.origin}/resources/knowledge-base#glossary-${props.id}`;
    });
    const { copy: copyLink, copied } = useClipboard({ source: url });
    const tooltipText = computed(() => {
      return copied.value
        ? translate({ path: 'KNOWLEDGE_BASE.LINK_COPIED' })
        : translate({ path: 'KNOWLEDGE_BASE.COPY_LINK' });
    });

    return {
      url,
      translate,
      shouldShowIcon,
      copyLink,
      tooltipText,
    };
  },
});
</script>
