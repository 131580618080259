<template>
  <div>
    <component
      :is="BToast"
      v-for="toast of toasts"
      :key="toast.title"
      :variant="toast.variant"
      :auto-hide-delay="toast.autoHideDelay"
      :no-auto-hide="toast.noAutoHide"
      :visible="true"
      toast-class="d-print-none"
      body-class="d-none"
      @hidden="
        () => {
          onToastHidden(toast);
        }
      "
    >
      <template #toast-title>
        <!-- We can't leverage the href prop in toasts because that prop only creates
              a link for the toast BODY, which by default we do not display. So instead
              we add the link to the title -->
        <b-link
          v-if="toast.href"
          :href="toast.href"
          target="_blank"
        >
          {{ toast.title }}
        </b-link>
        <span v-else>
          {{ toast.title }}
        </span>
      </template>
    </component>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { BToast } from 'bootstrap-vue';
import { useToasts } from '@/composables/useToasts';
import { ToastVariant } from '@/constants/ToastVariant';
import { DISPLAY_TOAST_EVENT, ToastOptions } from '@/types/ToastEvents';

enum AutoHideDelay {
  SHORT = 5000,
  MEDIUM = 15000,
  LONG = 30000,
}

const getDelay = (variant: ToastVariant, options: ToastOptions = {}): AutoHideDelay => {
  if (variant === ToastVariant.SUCCESS) {
    if (options.href) return AutoHideDelay.MEDIUM;
    return AutoHideDelay.SHORT;
  }

  if (variant === ToastVariant.WARNING) return AutoHideDelay.MEDIUM;

  return AutoHideDelay.LONG;
};

export default defineComponent({
  name: 'PremialabToaster',
  component: {
    BToast,
  },
  setup() {
    const { toaster } = useToasts();

    const toasts = ref<ToastOptions[]>([]);
    const toastMessages = computed(() => toasts.value.map((t) => t.title));

    const createToast = (variant: ToastVariant, options: ToastOptions) => {
      if (toastMessages.value.includes(options.title)) {
        return;
      }

      toasts.value = [
        ...toasts.value,
        {
          title: options.title,
          variant,
          autoHideDelay: getDelay(variant, options),
          ...options,
        },
      ];
    };

    const onDisplayToastEventBus = ({ variant, options }: { variant: ToastVariant; options: ToastOptions }) => {
      createToast(variant, options);
    };

    const onToastHidden = (toastToHide: ToastOptions) => {
      // Cast the toastToHide.title to string as ts spits out
      // `Type instantiation is excessively deep and possibly infinite. ts(2589)`
      // because ts cannot properly handle the type `string | VNode | VNode[]`.
      const idxToRemove = toastMessages.value.indexOf(toastToHide.title as string);
      if (idxToRemove > -1) {
        toasts.value.splice(idxToRemove, 1);
      }
    };

    onMounted(() => {
      toaster.on(DISPLAY_TOAST_EVENT, onDisplayToastEventBus);
    });
    onBeforeUnmount(() => {
      toaster.off(DISPLAY_TOAST_EVENT, onDisplayToastEventBus);
    });

    return {
      toasts,
      BToast,
      onToastHidden,
    };
  },
});
</script>
